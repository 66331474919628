import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import {
  FormatedSolicitudVale,
  SolicitudForm,
} from "../data/interfaces/Cedula";
import { getVars } from "../GlobalStorage";
import { isNullOrUndefined } from "../utils/emptyValidations";
import { ValesValidator } from "../data/validators/Cedula";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Notifications from "react-notify-toast";
import swal from "sweetalert";

const SolicitudVale = (props) => {
  const { data, back } = props;
  console.log(data);
  const history = useHistory();
  const Datos = data ? data : null;
  const [userData, setUserData] = useState();
  const token = getVars("Token").token;
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [form, setForm] = useState(SolicitudForm);
  const [loadingForm, setIsLoadingForm] = useState(true);
  const [isForEdit, setIsForEdit] = useState(false);
  const [errors, setErrors] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [errorsIne, setErrorsIne] = useState({
    error: false,
    seccion: -1,
    message: "",
  });

  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 29);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
  }, [userData]);

  useEffect(() => {
    setIsLoadingForm(true);
    let data = { ...form };
    data.Files = [];
    data.ArchivosClasificacion = [];
    if (Datos !== null) {
      setIsForEdit(true);
      data = FormatedSolicitudVale(Datos);
      setIsLoadingForm(false);
    }
    setTimeout(() => {
      setForm(data);
      setIsLoadingForm(false);
      setFormLoaded(true);
    }, 4000);
  }, [Datos]);

  function verifyCURP(CURP) {
    return new Promise((resolve) => {
      if (CURP.length < 18) {
        resolve(false);
      }
      fetch(
        `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/A94A3BAC-C920-4290-82FD-C1F2A4044DBB`
      )
        .then((res) => res.json())
        .then((dataRS) => {
          if (dataRS.Mensaje != "OK") {
            resolve(false);
          } else resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  function verifyTarjeta(data) {
    return new Promise((resolve) => {
      var urlencoded = new URLSearchParams();
      let curp = data.CURP;
      let folio = data.Tarjeta;
      urlencoded.append("token", "dfee39bd-acd2-4c95-514d-74384d530496");
      urlencoded.append("CURP", curp);

      var requestInfo = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        "https://peb.guanajuato.gob.mx/Tarjeta/api/APIBusquedaDatos/SETBusquedaCLUB",
        requestInfo
      )
        .then((response) => {
          return response.json();
        })
        .then((dataRS) => {
          if (dataRS.result === 0) {
            resolve(false);
          } else {
            if (dataRS.data[0].Folio === folio) {
              resolve(true);
            }
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  const saveSolicitud = async (redirect = 0) => {
    const hasErrors = ValesValidator(form);
    setErrors(hasErrors);
    if (!hasErrors.error) {
      setIsLoadingForm(true);
      let formData = { ...form };
      uploadFiles({ data: formData, api: "vales/updateValeSolicitud", token })
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            swal({
              title: "Guardado con éxito",
              text: res.message,
              icon: "success",
            });
            setIsLoadingForm(false);
            back();
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
          }
          setIsLoadingForm(false);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
        });
    } else {
      form.ListaParaEnviar = 0;
    }
  };

  useEffect(() => {
    if (!isNaN(parseInt(form.FechaINE))) {
      if (form.FechaINE.length === 4) {
        if (form.FechaINE !== null && form.FechaINE !== "") {
          //if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
          if (parseInt(form.FechaINE) < 2022) {
            const errors = {
              error: true,
              seccion: 1,
              message: "La vigencia de la Identificación Oficial no es válida",
            };
            setErrorsIne(errors);
          } else {
            const errors = { error: false, seccion: -1, message: "" };
            setErrorsIne(errors);
          }
        }
      } else {
        if (form.FechaINE.length > 0) {
          const errors = {
            error: true,
            seccion: 1,
            message: "La vigencia de la Identificación Oficial no es válida",
          };
          setErrorsIne(errors);
        }
      }
    }
  }, [form.FechaINE]);

  return (
    <>
      <Notifications />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Button color="success" onClick={back}>
                  Regresar
                </Button>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5" style={{ textAlign: "center" }}>
                  Editar solicitud
                  {loadingForm && <Spinner />}
                </CardTitle>
                {formLoaded && (
                  <Form>
                    <Col sm="12" style={{ textAlign: "center", color: "red" }}>
                      {form.idEstatus === 9 ? (
                        <CardText>
                          La solicitud fue marcada como lista para enviar, NO se
                          puede editar
                        </CardText>
                      ) : form.idEstatus === 10 ? (
                        <CardText>
                          La solicitud esta en revisión, NO se puede editar
                        </CardText>
                      ) : form.idEstatus === 8 ? (
                        <CardText>
                          La solicitud fue enviada a Ventanilla, NO puede
                          editarse
                        </CardText>
                      ) : (
                        <CardText></CardText>
                      )}
                    </Col>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 0
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <Row>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Fecha solicitud</Label>
                          {/* <span className="text-danger">*</span> */}
                          <Input
                            id="fecha"
                            type="text"
                            value={form.FechaSolicitud}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Folio Tarjeta Contigo Si</Label>
                          <Input
                            id="folio"
                            type="text"
                            value={form.FolioTarjetaImpulso}
                            maxLength={10}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            CURP
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CURP"
                            type="text"
                            value={form.CURP}
                            disabled={true}
                            maxLength={18}
                            style={{ textTransform: "uppercase" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            Folio de solicitud
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="FolioSolicitud"
                            type="text"
                            disabled={true}
                            value={form.FolioSolicitud}
                            style={{ textTransform: "uppercase" }}
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 0 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          (errors.error && errors.seccion === 1) ||
                          (errorsIne.error && errorsIne.seccion === 1)
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      I. DATOS DEL SOLICITANTE
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Nombre(s) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Nombre}
                            onChange={(event) => {
                              setForm({
                                ...form,
                                Nombre: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Primer apellido
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="paterno"
                            type="text"
                            value={form.Paterno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Paterno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Segundo apellido
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Materno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Materno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label check>
                            Sexo
                            <span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="Hombre"
                                  type="radio"
                                  disabled={true}
                                  checked={
                                    form.Sexo === "H" || form.Sexo === ""
                                  }
                                />{" "}
                                <Label check>Hombre</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="Mujer"
                                  type="radio"
                                  checked={form.Sexo === "M"}
                                  disabled={true}
                                />{" "}
                                <Label check>Mujer</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Vigencia INE:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="FechaINE"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={4}
                            disabled={true}
                            value={form.FechaINE}
                          />
                        </FormGroup>
                      </Col>

                      {errors.error && errors.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      ) : errorsIne.error && errorsIne.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errorsIne.message}
                          </CardText>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 4
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      II. DATOS DE DOMICILIO
                    </CardSubtitle>
                    <Row>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Calle: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Calle"
                            type="text"
                            value={form.Calle}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>
                            No. Exterior: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="NoExtVive"
                            type="text"
                            disabled={true}
                            value={form.NumExt}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>No. Interior: </Label>
                          <Input
                            name="NoIntVive"
                            type="text"
                            disabled={true}
                            value={form.NumInt}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>Código Postal:</Label>
                          <span className="text-danger">*</span>
                          <Input
                            name="CPVive"
                            type="text"
                            disabled={true}
                            pattern="[0-9]*"
                            maxLength={5}
                            value={form.CP}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="6">
                        <FormGroup>
                          <Label>
                            Colonia: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="ColoniaVive"
                            type="text"
                            disabled={true}
                            value={form.Colonia}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        {!loadingForm && (
                          <FormGroup>
                            <Label>Municipio:</Label>
                            <span className="text-danger">*</span>
                            <Input
                              name="Municipio"
                              type="text"
                              disabled={true}
                              value={form.Municipio}
                            ></Input>
                          </FormGroup>
                        )}
                      </Col>
                      <Col sm="6" xs="6">
                        <FormGroup>
                          <Label>Localidad:</Label>
                          <span className="text-danger">*</span>

                          <Input
                            name="Localidad"
                            type="text"
                            disabled={true}
                            value={form.Localidad}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Estado: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="idEntidadVive"
                            type="text"
                            disabled={true}
                            value={"GUANAJUATO"}
                          ></Input>
                        </FormGroup>
                      </Col>

                      {errors.error && errors.seccion === 4 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 2
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      III. DATOS DE CONTACTO
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Teléfono celular:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="telefono"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Celular}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Teléfono fijo:</Label>
                          <Input
                            name="telefonoF"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Telefono}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Correo electrónico:</Label>
                          <Input
                            name="telefono"
                            type="text"
                            value={form.Correo}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 2 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor: "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      V. RESPONSABLE DE ENTREGA
                    </CardSubtitle>
                    <Row>
                      <Col sm="12" xs="12">
                        <FormGroup>
                          <Label>Responsable de Entrega:</Label>
                          <span className="text-danger">*</span>
                          <Input
                            name="Enlace"
                            type="text"
                            value={form.ResponsableEntrega}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 6 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 7
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <Row>
                      <Col sm="12" style={{ textAlign: "center" }}>
                        <Button
                          color="danger"
                          disabled={loadingForm}
                          onClick={back}
                        >
                          Cancelar
                        </Button>

                        <>
                          {/* {isForEdit &&
                            menu.Eliminar === 1 &&
                            form.idEstatus === 1 &&
                            !loadingForm && (
                              <Button
                                color="danger"
                                disabled={loadingForm}
                                onClick={() => deleteAlert()}
                              >
                                Eliminar
                              </Button>
                            )} */}

                          {menu.Agregar === 1 &&
                            menu.Editar === 1 &&
                            (userData.user.id === 1 ||
                              userData.user.id === 1836 ||
                              userData.user.id === 55) &&
                            !loadingForm && (
                              <Button onClick={() => saveSolicitud()}>
                                Guardar
                              </Button>
                            )}
                        </>

                        {loadingForm && <Spinner />}
                      </Col>
                    </Row>
                  </Form>
                )}
                <Row>
                  <Col sm="12" style={{ textAlign: "center", color: "red" }}>
                    {errors.error && (
                      <CardText>
                        Existen errores, por favor verifique el formulario
                      </CardText>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SolicitudVale;

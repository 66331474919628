import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import moment from "moment";

import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import {
  CedulaFormInterface,
  FormatNullCedulaFields,
} from "../data/interfaces/Cedula";
import { getVars } from "../GlobalStorage";
import {
  isEmptyOrInvalidString,
  isEmptyOrNullObject,
  isNullOrUndefined,
} from "../utils/emptyValidations";
import { CedulaValidator } from "../data/validators/Cedula";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Notifications from "react-notify-toast";
import DateTimeComponent from "../components/DateTimeComponent";
import swal from "sweetalert";
import ListadoArchivos from "../components/Archivos/ListadoArchivos";

const CedulaForm = (props) => {
  const history = useHistory();
  const location = useLocation();
  const id = location.state ? location.state.data.idCedula : -1;
  const Datos = location.state ? location.state.data : null;
  const programa = location.state ? location.state.programa : 1;
  const token = getVars("Token").token;
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isForEdit, setIsForEdit] = useState(false);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [form, setForm] = useState(CedulaFormInterface);
  const [errors, setErrors] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [catalogs, setCatalogs] = useState({
    entidades: [],
    cat_parentesco_jefe_hogar: [],
    cat_parentesco_tutor: [],
    cat_situacion_actual: [],
    cat_estado_civil: [],
    cat_actividades: [],
    cat_codigos_dificultad: [],
    cat_enfermedades: [],
    cat_grados_educacion: [],
    cat_niveles_educacion: [],
    cat_prestaciones: [],
    cat_situacion_actual: [],
    cat_tipo_seguro: [],
    cat_tipos_combustibles: [],
    cat_tipos_drenajes: [],
    cat_tipos_luz: [],
    cat_tipos_muros: [],
    cat_tipos_pisos: [],
    cat_tipos_techos: [],
    cat_tipos_viviendas: [],
    cat_tipos_agua: [],
    archivos_clasificacion: [],
    municipios: [],
    localidades: [],
    agebs: [],
    manzanas: [],
  });
  const periodos = [
    { value: "", label: "" },
    { value: 1, label: "Cada semana" },
    { value: 2, label: "Cada mes" },
    { value: 3, label: "Cada dos meses" },
    { value: 4, label: "Cada tres meses" },
    { value: 5, label: "Cada seis meses" },
    { value: 6, label: "Cada año" },
  ];
  const [tokenTarjeta, setTokenTarjeta] = useState("");
  const [datosTarjeta, setDatostarjeta] = useState({});
  const [loadingFolio, setLoadingFolio] = useState(false);
  const [loadingCURP, setLoadingCURP] = useState(false);
  const [necesitaTutor, setNecesitaTutor] = useState(0);
  const [tieneEnfermedad, setTieneEnfermedad] = useState(0);

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 13);
    if (
      isNullOrUndefined(currentMenu) ||
      currentMenu.Ver < 1 ||
      currentMenu.Agregar < 1
    ) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
  }, [userData]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  // useEffect(() => {
  //   const requestInfo = {
  //     method: "POST",
  //     body: new URLSearchParams({
  //       username: "destradav@guanajuato.gob.mx",
  //       password: "Destradav1.",
  //       grant_type: "password",
  //     }),
  //   };
  //   fetch("https://peb.guanajuato.gob.mx/DEV/token", requestInfo)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error(`Ocurrio un error: ${response.statusText}`);
  //     })
  //     .then((dataRS) => {
  //       setTokenTarjeta(dataRS.access_token);
  //     })
  //     .catch((error) => {
  //       setLoadingCatalogs(false);
  //       swal({
  //         title: "Ha ocurrido un error",
  //         text: error.error,
  //         icon: "warning",
  //       });
  //     });
  // }, []);

  useEffect(() => {
    if (isNullOrUndefined(Datos.idSolicitud)) {
      history.push("/cedula-list");
    }
    if (Datos) {
      setIsLoadingForm(true);
      let formData = { ...form, ...Datos };
      formData.ListaParaEnviar = formData.ListaParaEnviar
        ? formData.ListaParaEnviar
        : 0;
      formData = FormatNullCedulaFields(formData);
      const table = programa > 1 ? "getByIdC" : "getByIdV";
      if (Datos.idCedula) {
        EjecutarApi({}, `cedula/${table}/${Datos.idCedula}`, token, "GET")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            if (res.success && res.results) {
              setIsForEdit(true);
              let newForm = FormatNullCedulaFields(res.data);
              // setForm();
              handleTieneEnfermedad(newForm, true);
            } else {
              swal({
                title: "Ha ocurrido un error",
                text: res.errors,
                icon: "warning",
              });
              history.push("/cedula-list");
            }
            setIsLoadingForm(false);
          })
          .catch((error) => {
            setIsLoadingForm(false);
            swal({
              title: "Ha ocurrido un error",
              text: error.error,
              icon: "warning",
            });
            history.push("/cedula-list");
          });
      } else {
        // setForm(formData);
        setTimeout(() => {
          handleTieneEnfermedad(formData, false);
          setIsLoadingForm(false);
        }, 2000);
      }
    }
  }, [Datos]);

  useEffect(() => {
    if (!isNaN(parseInt(form.MunicipioVive))) {
      getLocalities(form.MunicipioVive);
    }
  }, [form.MunicipioVive]);

  useEffect(() => {
    if (form.idEntidadVive === 12) {
      let formCatalogs = {};
      if (catalogs.municipios.length > 0) {
        const MunicipioVive = catalogs.municipios.find(
          (x) => x.label === form.MunicipioVive
        );
        if (!isNullOrUndefined(MunicipioVive)) {
          formCatalogs.MunicipioVive = MunicipioVive.value;
        }
      }
      if (catalogs.localidades.length > 0) {
        const LocalidadVive = catalogs.localidades.find(
          (x) => x.label === form.LocalidadVive
        );
        if (!isNullOrUndefined(LocalidadVive)) {
          formCatalogs.LocalidadVive = LocalidadVive.value;
        }
      }
      if (catalogs.agebs.length > 0 && form.AGEBVive !== "") {
        let AGEBVive = catalogs.agebs.find((x) => x.label === form.AGEBVive);
        if (!isNullOrUndefined(AGEBVive)) {
          formCatalogs.AGEBVive = AGEBVive.value;
        }
      }
      if (catalogs.manzanas.length > 0 && form.ManzanaVive !== "") {
        let ManzanaVive = catalogs.manzanas.find(
          (x) => x.label === form.ManzanaVive
        );
        if (!isNullOrUndefined(ManzanaVive)) {
          formCatalogs.ManzanaVive = ManzanaVive.value;
        }
      }
      setForm({
        ...form,
        ...formCatalogs,
      });
    }
  }, [
    catalogs.municipios,
    catalogs.agebs,
    catalogs.localidades,
    catalogs.manzanas,
    isLoadingForm,
  ]);

  useEffect(() => {
    if (!isNaN(parseInt(form.LocalidadVive))) {
      EjecutarApi(
        {},
        `cedula/getAgebsManzanasByLocalidad/${form.LocalidadVive}`,
        token,
        "GET"
      )
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            let cat = res.data;
            setCatalogs({
              ...catalogs,
              agebs: cat.agebs,
              manzanas: cat.manzanas,
            });
            setForm({
              ...form,
              TipoAsentamientoVive: cat.ambito.Ambito === "R" ? 2 : 1,
            });
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
          }
          setLoadingCatalogs(false);
        })
        .catch((error) => {
          setLoadingCatalogs(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
        });
    }
  }, [form.LocalidadVive]);

  useEffect(() => {
    EjecutarApi({}, "cedula/getCatalogsCedulaCompletos", token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          cat.localidades = [];
          cat.agebs = [];
          cat.manzanas = [];
          setCatalogs(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  }, [token]);

  const getLocalities = (municipio) => {
    EjecutarApi(
      {},
      `cedula/getLocalidadesByMunicipio/${municipio}`,
      token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatalogs({
            ...catalogs,
            localidades: res.data,
          });
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const validateData = async (type, value) => {
    let data = {};
    data[type] = value;
    var requestInfo = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${tokenTarjeta}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data),
    };
    setLoadingCURP(true);
    setLoadingFolio(true);
    fetch("https://peb.guanajuato.gob.mx/DEV/api/Tarjeta", requestInfo)
      .then((response) => {
        if (response.status !== 200)
          return { error: "El beneficiario no fue encontrado" };
        return response.json();
      })
      .then((dataRS) => {
        if (dataRS.error) {
          if (type === "curp") {
            validateCurp(value);
          } else {
            setLoadingCURP(false);
            swal({
              title: "¡Atención!",
              text: dataRS.error,
              icon: "warning",
            });
          }
          setLoadingFolio(false);
        } else {
          setLoadingFolio(false);
          setLoadingCURP(false);
          setDatostarjeta(dataRS);
        }
      })
      .catch((error) => {
        setLoadingFolio(false);
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  const validateCurp = (CURP) => {
    if (CURP.length < 18) {
      return;
    }
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/A94A3BAC-C920-4290-82FD-C1F2A4044DBB`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        if (dataRS.Mensaje === "OK") {
          const response = dataRS.Resultado;
          const fechaNacimiento = moment(response.fechNac, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          let data = {
            Nombre: response.nombres,
            Paterno: response.apellido1,
            Materno: response.apellido2,
            Sexo: response.sexo,
            FechaNacimiento: fechaNacimiento,
            Edad: getAgeByDate(fechaNacimiento),
            idEntidadNacimiento: getEstadoNacimiento(response.cveEntidadNac),
            FolioTarjetaImpulso: "",
            TarjetaImpulso: "",
            ContactoTarjetaImpulso: 0,
          };
          setForm({
            ...form,
            ...data,
          });
          setDatostarjeta({});
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: dataRS.message,
            icon: "warning",
          });
        }
        setLoadingCURP(false);
      })
      .catch((error) => {
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    if (!isEmptyOrNullObject(datosTarjeta)) {
      let data = {
        CURP: datosTarjeta.Persona_CURP,
        Sexo: datosTarjeta.Persona_ClaveSexo,
        Edad: parseInt(datosTarjeta.Persona_Edad),
        FechaNacimiento: moment(
          datosTarjeta.Persona_Fecha_Nacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        FolioTarjetaImpulso: datosTarjeta.Persona_Folio,
        Nombre: datosTarjeta.Persona_Nombres,
        Paterno: datosTarjeta.Persona_Primer_Apellido,
        Materno: datosTarjeta.Persona_Segundo_Apellido,
      };
      if (!isEmptyOrInvalidString(datosTarjeta.Persona_Folio)) {
        data.TarjetaImpulso = 3;
        data.ContactoTarjetaImpulso = 0;
      }
      data.idEntidadNacimiento = getEstadoNacimiento(
        datosTarjeta.Persona_EntidadNacimiento
      );
      setForm({
        ...form,
        ...data,
      });
    }
  }, [datosTarjeta]);

  const getAgeByDate = (fechaNacimiento) => {
    return moment().diff(fechaNacimiento, "years");
  };

  const getEstadoNacimiento = (estadoCurp) => {
    const found = catalogs.entidades.find(
      (element) => element.Clave_CURP === estadoCurp
    );
    if (!isNullOrUndefined(found)) {
      return found.value;
    }
    return "";
  };

  const handleSelectMultiple = (value, key) => {
    let array = [...form[key]];
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push(value);
    }
    let formAux = { ...form };
    formAux[key] = array;
    setForm(formAux);
  };

  const handleAsisteEscuela = (value) => {
    let values = {
      AsisteEscuela: value,
      idNivelEscuela: "",
      idGradoEscuela: "",
    };
    if (value === "0") {
      values.idNivelEscuela = 11;
      values.idGradoEscuela = 7;
    }

    setForm({ ...form, ...values });
  };

  const handleChangeArchivos = (files) => {
    setForm({
      ...form,
      Files: files,
    });
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setForm({
      ...form,
      ArchivosClasificacion: arrayClasificacion,
    });
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setForm({
      ...form,
      Files: files,
      ArchivosClasificacion: arrayClasificacion,
    });
  };

  const handleTieneEnfermedad = (formData, isForEdit) => {
    let enfermedadForm = {
      DificultadMovilidad: "",
      DificultadVer: "",
      DificultadHablar: "",
      DificultadOir: "",
      DificultadVestirse: "",
      DificultadRecordar: "",
      DificultadBrazos: "",
      DificultadMental: "",
      Enfermedades: [],
    };
    if (!isForEdit) {
      setTieneEnfermedad(0);
      enfermedadForm = {
        DificultadMovilidad: 1,
        DificultadVer: 1,
        DificultadHablar: 1,
        DificultadOir: 1,
        DificultadVestirse: 1,
        DificultadRecordar: 1,
        DificultadBrazos: 1,
        DificultadMental: 2,
        Enfermedades: [16],
      };
    } else if (
      formData.DificultadMovilidad !== 1 ||
      formData.DificultadVer !== 1 ||
      formData.DificultadHablar !== 1 ||
      formData.DificultadOir !== 1 ||
      formData.DificultadVestirse !== 1 ||
      formData.DificultadRecordar !== 1 ||
      formData.DificultadBrazos !== 1 ||
      formData.DificultadMental !== 2 ||
      formData.Enfermedades.find((x) => x !== 16)
    ) {
      setTieneEnfermedad(1);
      enfermedadForm = {
        DificultadMovilidad: formData.DificultadMovilidad,
        DificultadVer: formData.DificultadVer,
        DificultadHablar: formData.DificultadHablar,
        DificultadOir: formData.DificultadOir,
        DificultadVestirse: formData.DificultadVestirse,
        DificultadRecordar: formData.DificultadRecordar,
        DificultadBrazos: formData.DificultadBrazos,
        DificultadMental: formData.DificultadMental,
        Enfermedades: formData.Enfermedades,
      };
    } else {
      setTieneEnfermedad(0);
      enfermedadForm = {
        DificultadMovilidad: 1,
        DificultadVer: 1,
        DificultadHablar: 1,
        DificultadOir: 1,
        DificultadVestirse: 1,
        DificultadRecordar: 1,
        DificultadBrazos: 1,
        DificultadMental: 2,
        Enfermedades: [16],
      };
    }
    setForm({
      ...formData,
      ...enfermedadForm,
    });
  };

  const handleChangeTieneEnfermedad = (value) => {
    let enfermedadForm = {
      DificultadMovilidad: "",
      DificultadVer: "",
      DificultadHablar: "",
      DificultadOir: "",
      DificultadVestirse: "",
      DificultadRecordar: "",
      DificultadBrazos: "",
      DificultadMental: "",
      Enfermedades: [],
    };
    if (value === 0) {
      enfermedadForm = {
        DificultadMovilidad: 1,
        DificultadVer: 1,
        DificultadHablar: 1,
        DificultadOir: 1,
        DificultadVestirse: 1,
        DificultadRecordar: 1,
        DificultadBrazos: 1,
        DificultadMental: 2,
        Enfermedades: [16],
      };
    }
    setForm({
      ...form,
      ...enfermedadForm,
    });
    setTieneEnfermedad(value);
  };

  const handleChangeEstadoVive = (value) => {
    setForm({
      ...form,
      idEntidadVive: value,
      LocalidadVive: "",
      MunicipioVive: "",
    });
  };

  const handleChangeMunicipio = (municipio) => {
    getLocalities(municipio);
    setForm({
      ...form,
      MunicipioVive: municipio,
    });
  };

  const saveAlert = () => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro de poner la solicitud como 'Lista para enviar'?, no podrá ser editada después",
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        save(true);
      }
    });
  };

  const save = (listo) => {
    const hasErrors = { error: false, seccion: -1, message: "" }; //CedulaValidator(form, necesitaTutor);
    setErrors(hasErrors);
    if (!hasErrors.error) {
      setIsLoadingForm(true);
      let formAux = { ...form };
      if (listo) formAux.ListaParaEnviar = 1;
      const url = isForEdit ? "update" : "create";
      let oldFiles = [];
      let newFiles = [];
      let oldClasification = [];
      let newClasification = [];

      formAux.Files.forEach((file, index) => {
        if (isNullOrUndefined(file.id)) {
          newFiles.push(file);
          newClasification.push(formAux.ArchivosClasificacion[index]);
        } else {
          oldFiles.push(file);
          oldClasification.push(formAux.ArchivosClasificacion[index]);
        }
      });
      formAux.OldFiles = oldFiles;
      formAux.OldClasificacion = oldClasification;
      formAux.NewFiles = newFiles;
      formAux.NewClasificacion = newClasification;
      formAux.Files = [];
      formAux.ArchivosClasificacion = [];
      form.NoIntVive = form.NoIntVive === "" ? "S/N" : form.NoIntVive;
      if (formAux.idEntidadVive !== "" && formAux.idEntidadVive === 12) {
        formAux.MunicipioVive =
          formAux.MunicipioVive !== ""
            ? catalogs.municipios.find(
                (x) => x.value === parseInt(formAux.MunicipioVive)
              ).label
            : "";
        formAux.LocalidadVive =
          formAux.LocalidadVive !== ""
            ? catalogs.localidades.find(
                (x) => x.value === parseInt(formAux.LocalidadVive)
              ).label
            : "";
      }
      formAux.id = id;
      uploadFiles({ data: formAux, api: `cedula/${url}`, token })
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            swal({
              title: "¡Éxito!",
              icon: "success",
            });
            history.push("/cedula-list");
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
          }
          setIsLoadingForm(false);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
        });
    }
  };

  const deleteAlert = () => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro de eliminar la solicitud?",
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        deleteCedula();
      }
    });
  };

  const deleteCedula = () => {
    EjecutarApi({ id: id, programa: programa }, "cedula/delete", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Eliminada con éxito!",
            icon: "success",
          });
          history.push("/cedula-list");
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setIsLoadingForm(false);
      })
      .catch((error) => {
        setIsLoadingForm(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Link to="/cedula-list">
                      <Button color="success">Regresar</Button>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <CardTitle tag="h5" style={{ textAlign: "center" }}>
                      {isForEdit && form.ListaParaEnviar === 0
                        ? "Editar"
                        : form.ListaParaEnviar === 1
                        ? "Ver"
                        : "Nueva"}{" "}
                      cedula
                      {isLoadingForm && <Spinner />}
                    </CardTitle>
                    <Form>
                      <Col
                        sm="12"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        {form.ListaParaEnviar === 1 && (
                          <CardText>
                            La cedula está lista para enviarse, no puede ser
                            editada
                          </CardText>
                        )}
                      </Col>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 0
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <Row>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              Fecha solicitud
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <DateTimeComponent
                              id="FechaEntrega"
                              name="FechaEntrega"
                              dateFormat="YYYY-MM-DD"
                              maxDate={moment()
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              minDate={new Date("2020-01-01")}
                              timeFormat={false}
                              closeOnSelect={true}
                              defaultValue={form.FechaSolicitud}
                              value={form.FechaSolicitud}
                              inputProps={{ readOnly: true }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  FechaSolicitud: moment(e._d).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>Folio tarjeta impulso</Label>
                            <Input
                              id="folio"
                              type="text"
                              value={form.FolioTarjetaImpulso}
                              maxLength={10}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  FolioTarjetaImpulso: event.target.value,
                                })
                              }
                            />
                            {form.ListaParaEnviar === 0 && (
                              <Button
                                size="sm"
                                color="primary"
                                disabled={form.FolioTarjetaImpulso.length === 0}
                                onClick={() =>
                                  validateData(
                                    "folio",
                                    form.FolioTarjetaImpulso
                                  )
                                }
                              >
                                Validar {loadingFolio && <Spinner size="sm" />}
                              </Button>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              CURP{/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="CURP"
                              type="text"
                              value={form.CURP}
                              style={{ textTransform: "uppercase" }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CURP: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            {form.ListaParaEnviar === 0 && (
                              <Button
                                size="sm"
                                color="primary"
                                disabled={form.CURP.length != 18}
                                onClick={() => validateData("curp", form.CURP)}
                              >
                                Validar {loadingCURP && <Spinner size="sm" />}
                              </Button>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>
                              Folio de cédula
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="CURP"
                              type="text"
                              value={form.Folio}
                              style={{ textTransform: "uppercase" }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Folio: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 0 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 1
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        I. DATOS DEL SOLICITANTE
                      </CardSubtitle>
                      <Row>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Nombre(s){" "}
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              id="nombre"
                              type="text"
                              value={form.Nombre}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Nombre: event.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Primer apellido
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              id="paterno"
                              type="text"
                              value={form.Paterno}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Paterno: event.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Segundo apellido
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              id="nombre"
                              type="text"
                              value={form.Materno}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Materno: event.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Fecha de nacimiento
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <DateTimeComponent
                              id="FechaNacimiento"
                              name="FechaNacimiento"
                              dateFormat="YYYY-MM-DD"
                              maxDate={moment()
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              minDate={new Date("1920-01-01")}
                              value={form.FechaNacimiento}
                              timeFormat={false}
                              closeOnSelect={true}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  FechaNacimiento: moment(e._d).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              }
                              inputProps={{ readOnly: true }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Edad{/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              id="nombre"
                              type="number"
                              value={form.Edad}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Edad: event.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label check>
                              Sexo{/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="Hombre"
                                    type="radio"
                                    checked={
                                      form.Sexo === "H" || form.Sexo === ""
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Sexo: "H",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Hombre</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="Mujer"
                                    type="radio"
                                    checked={form.Sexo === "M"}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Sexo: "M",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Mujer</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Entidad de nacimiento
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="EntidadNacimiento"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idEntidadNacimiento}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idEntidadNacimiento: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>
                              {catalogs.entidades.map((entidad) => (
                                <option
                                  key={entidad.value}
                                  value={entidad.value}
                                >
                                  {entidad.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>RFC</Label>
                            <Input
                              name="RFC"
                              type="text"
                              value={form.RFC}
                              style={{ textTransform: "uppercase" }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  RFC: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Estado civil
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="EstadoCivil"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idEstadoCivil}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idEstadoCivil: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>

                              {catalogs.cat_estado_civil.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Parentesco con Jefa(e) del hogar:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="JefeHogar"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idParentescoJefeHogar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idParentescoJefeHogar: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>

                              {catalogs.cat_parentesco_jefe_hogar.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="6">
                          <FormGroup>
                            <Label>
                              Número de hijos:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="NumHijos"
                              type="number"
                              value={form.NumHijos}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NumHijos: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="6">
                          <FormGroup>
                            <Label>
                              Número de hijas:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="NumHijas"
                              type="number"
                              value={form.NumHijas}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NumHijas: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Pueblo o comunidad indígena (dejar vacío si no
                              aplica):
                            </Label>
                            <Input
                              name="ComunidadIndigena"
                              type="text"
                              value={form.ComunidadIndigena}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  ComunidadIndigena: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Lengua indígena o dialecto (dejar vacío si no
                              aplica):
                            </Label>
                            <Input
                              name="Dialecto"
                              type="text"
                              value={form.Dialecto}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Dialecto: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿Se considera afro mexicano(a) o afrodescendiente?
                              :{/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noAfro"
                                    type="radio"
                                    checked={
                                      form.Afromexicano === 0 ||
                                      form.Afromexicano === ""
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Afromexicano: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siAfro"
                                    type="radio"
                                    checked={form.Afromexicano === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Afromexicano: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Sí</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Por su situación actual, usted se considera:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="SituacionActual"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idSituacionActual}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idSituacionActual: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>

                              {catalogs.cat_situacion_actual.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿Usted está interesada(o) en obtener la Tarjeta
                              Impulso?
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="TarjetaImpulso"
                              type="select"
                              disabled={
                                (datosTarjeta.Persona_Folio &&
                                  datosTarjeta.Persona_Folio !== "") ||
                                loadingCatalogs
                              }
                              value={form.TarjetaImpulso}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  TarjetaImpulso: e.target.value,
                                })
                              }
                            >
                              {[
                                { value: "", label: "" },
                                { value: 2, label: "No" },
                                { value: 1, label: "Si" },
                                { value: 3, label: "Ya cuenta con tarjeta" },
                              ].map((element) => (
                                <option
                                  key={element.label}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿Autoriza que un servidor(a) publico(a) lo
                              contacte para tramitar la Tarjeta Impulso? :
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="TarjetaNo"
                                    type="radio"
                                    checked={form.ContactoTarjetaImpulso === 0}
                                    disabled={
                                      (datosTarjeta.Persona_Folio &&
                                        datosTarjeta.Persona_Folio !== "") ||
                                      form.FolioTarjetaImpulso !== ""
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ContactoTarjetaImpulso: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="TarjetaSi"
                                    type="radio"
                                    disabled={
                                      (datosTarjeta.Persona_Folio &&
                                        datosTarjeta.Persona_Folio !== "") ||
                                      form.FolioTarjetaImpulso !== ""
                                    }
                                    checked={form.ContactoTarjetaImpulso === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ContactoTarjetaImpulso: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Sí</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {form.Edad > 17 && (
                          <Col sm="4" xs="12">
                            <FormGroup>
                              <Label check>
                                ¿La persona necesita un tutor?{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Row>
                                <Col style={{ marginLeft: "10%" }}>
                                  <FormGroup check>
                                    <Input
                                      name="Notutor"
                                      type="radio"
                                      checked={necesitaTutor === 0}
                                      onChange={() => setNecesitaTutor(0)}
                                    />{" "}
                                    <Label check>No</Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup check>
                                    <Input
                                      name="Situtor"
                                      type="radio"
                                      checked={necesitaTutor === 1}
                                      onChange={() => setNecesitaTutor(1)}
                                    />{" "}
                                    <Label check>Si</Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        )}
                        {errors.error && errors.seccion === 1 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 2
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        II. DATOS DE CONTACTO
                      </CardSubtitle>
                      <Row>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              Teléfono celular:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="telefono"
                              type="text"
                              value={form.Celular}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Celular: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>Correo electrónico:</Label>
                            <Input
                              name="telefono"
                              type="text"
                              value={form.Correo}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Correo: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>Teléfono fijo:</Label>
                            <Input
                              name="telefono"
                              type="text"
                              value={form.Telefono}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Telefono: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>Teléfono de recados:</Label>
                            <Input
                              name="telRecados"
                              type="text"
                              value={form.TelRecados}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  TelRecados: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 2 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 3
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        III. DATOS DE PADRE, MADRE O TUTOR(A) (en caso de
                        aplicar)
                      </CardSubtitle>
                      <Row>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Parentesco con la persona solicitante:
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span> 
                                ))} */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="SituacionActual"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idParentescoTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idParentescoTutor: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_parentesco_tutor.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Nombre(s):{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span> 
                                ))} */}
                            </Label>
                            <Input
                              name="NombreTutor"
                              type="text"
                              value={form.NombreTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NombreTutor: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Primer apellido:{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span>
                                ))} */}
                            </Label>
                            <Input
                              name="PaternoTutor"
                              type="text"
                              value={form.PaternoTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PaternoTutor: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Segundo apellido:{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span> 
                                ))} */}
                            </Label>
                            <Input
                              name="MaternoTutor"
                              type="text"
                              value={form.MaternoTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  MaternoTutor: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Fecha de nacimiento{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                   <span className="text-danger">*</span>
                                ))} */}
                            </Label>
                            <DateTimeComponent
                              id="FechaNacimientoTutor"
                              name="FechaNacimientoTutor"
                              dateFormat="YYYY-MM-DD"
                              maxDate={moment()
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              minDate={new Date("1920-01-01")}
                              value={form.FechaNacimientoTutor}
                              timeFormat={false}
                              closeOnSelect={true}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  FechaNacimientoTutor: moment(e._d).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              }
                              inputProps={{ readOnly: true }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Edad:{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span> 
                                ))} */}
                            </Label>
                            <Input
                              name="EdadTutor"
                              type="number"
                              value={form.EdadTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  EdadTutor: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label check>
                              Sexo{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span> 
                                ))} */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="HombreTutor"
                                    type="radio"
                                    checked={form.SexoTutor === "H"}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        SexoTutor: "H",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Hombre</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="MujerTutor"
                                    type="radio"
                                    checked={form.SexoTutor === "M"}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        SexoTutor: "M",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Mujer</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Entidad de nacimiento:{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                   <span className="text-danger">*</span> 
                                ))} */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="EntidadNacimientoTutor"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idEntidadNacimientoTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idEntidadNacimientoTutor: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.entidades.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              CURP:{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span> 
                                ))} */}
                            </Label>
                            <Input
                              name="CURPTutor"
                              type="text"
                              value={form.CURPTutor}
                              style={{ textTransform: "uppercase" }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CURPTutor: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Teléfono:{" "}
                              {/* {form.Edad < 18 ||
                                (necesitaTutor === 1 && (
                                  <span className="text-danger">*</span> 
                                ))} */}
                            </Label>
                            <Input
                              name="TelefonoTutor"
                              type="text"
                              value={form.TelefonoTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  TelefonoTutor: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>Correo:</Label>
                            <Input
                              name="CorreoTutor"
                              type="text"
                              value={form.CorreoTutor}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CorreoTutor: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 3 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 4
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        IV. DATOS DE DOMICILIO
                      </CardSubtitle>
                      <Row>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>Estado:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="idEntidadVive"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idEntidadVive}
                              onChange={(e) =>
                                handleChangeEstadoVive(e.target.value)
                              }
                            >
                              <option value={""}></option>
                              {catalogs.entidades.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          {form.idEntidadVive == 12 ? (
                            <FormGroup>
                              <Label>Municipio:</Label>
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                              <Input
                                name="MunicipioVive"
                                type="select"
                                disabled={loadingCatalogs}
                                value={form.MunicipioVive}
                                onChange={(e) =>
                                  handleChangeMunicipio(e.target.value)
                                }
                              >
                                <option value={""}></option>
                                {catalogs.municipios.map((element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          ) : (
                            <FormGroup>
                              <Label>Municipio:</Label>
                              {/* <span className="text-danger">*</span> */}
                              <Input
                                name="MunicipioVive"
                                type="text"
                                value={form.MunicipioVive}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    MunicipioVive: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          )}
                        </Col>
                        <Col sm="10" xs="12">
                          {form.idEntidadVive === 12 ? (
                            <FormGroup>
                              <Label>Localidad:</Label>
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                              <Input
                                name="MunicipioVive"
                                type="select"
                                disabled={
                                  loadingCatalogs || form.MunicipioVive === ""
                                }
                                value={form.LocalidadVive}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    LocalidadVive: e.target.value,
                                  })
                                }
                              >
                                <option value={""}></option>
                                {catalogs.localidades.map((element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          ) : (
                            <FormGroup>
                              <Label>
                                Localidad:{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                name="LocalidadVive"
                                type="text"
                                value={form.LocalidadVive}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    LocalidadVive: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          )}
                        </Col>
                        <Col sm="2" xs="12">
                          <FormGroup>
                            <Label>Código Postal:</Label>
                            {/* <span className="text-danger">*</span> */}
                            <Input
                              name="CPVive"
                              type="number"
                              value={form.CPVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CPVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>AGEB:</Label>
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="MunicipioVive"
                              type="select"
                              disabled={
                                loadingCatalogs ||
                                form.LocalidadVive === "" ||
                                catalogs.agebs.length === 0
                              }
                              value={form.AGEBVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  AGEBVive: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.agebs.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {/* <FormGroup>
                            <Label>AGEB:</Label>
                            <Input
                              name="AGEBVive"
                              type="text"
                              value={form.AGEBVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  AGEBVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup> */}
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Manzana:</Label>
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="MunicipioVive"
                              type="select"
                              disabled={
                                loadingCatalogs ||
                                form.LocalidadVive === "" ||
                                catalogs.manzanas.length === 0
                              }
                              value={form.ManzanaVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  ManzanaVive: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.manzanas.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {/* <FormGroup>
                            <Label>Manzana:</Label>
                            <span className="text-danger">*</span>
                            <Input
                              name="ManzanaVive"
                              type="text"
                              value={form.ManzanaVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  ManzanaVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup> */}
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Tipo de asentamiento:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="TipoAsentamientoVive"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.TipoAsentamientoVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  TipoAsentamientoVive: e.target.value,
                                })
                              }
                            >
                              {[
                                { value: "", label: "" },
                                { value: "Urbano", label: "Urbano" },
                                { value: "Rural", label: "Rural" },
                              ].map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="12" xs="12">
                          <FormGroup>
                            <Label>Colonia:</Label>
                            {/* <span className="text-danger">*</span> */}
                            <Input
                              name="ColoniaVive"
                              type="text"
                              value={form.ColoniaVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  ColoniaVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="8" xs="12">
                          <FormGroup>
                            <Label>Calle:</Label>
                            {/* <span className="text-danger">*</span> */}
                            <Input
                              name="CalleVive"
                              type="text"
                              value={form.CalleVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CalleVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="12">
                          <FormGroup>
                            <Label>
                              No. Exterior:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="NoExtVive"
                              type="text"
                              value={form.NoExtVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NoExtVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="12">
                          <FormGroup>
                            <Label>No. Interior:</Label>
                            <Input
                              name="NoIntVive"
                              type="text"
                              value={form.NoIntVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NoIntVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12" xs="12">
                          <FormGroup>
                            <Label>
                              Referencias:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="Referencias"
                              type="textarea"
                              value={form.Referencias}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Referencias: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 4 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 5
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        V. DATOS DEL HOGAR
                      </CardSubtitle>
                      <Row>
                        <Label sm="10">
                          ¿Cuántos hogares o grupos de personas tienen gastos
                          separados para comer contando el de usted dentro de su
                          vivienda?
                          {/* <span className="text-danger">*</span> */}
                        </Label>
                        <Col sm="2" xs="12">
                          <FormGroup>
                            <Input
                              name="TotalHogares"
                              type="number"
                              value={form.TotalHogares}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  TotalHogares: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              No. de mujeres en su hogar
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="TotalHogares"
                              type="number"
                              value={form.NumeroMujeresHogar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NumeroMujeresHogar: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              No. de hombres en su hogar
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="TotalHogares"
                              type="number"
                              value={form.NumeroHombresHogar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NumeroHombresHogar: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Menores de 18 años
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noMayoresEdad"
                                    type="radio"
                                    checked={form.PersonasMayoresEdad === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        PersonasMayoresEdad: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siMayoresEdad"
                                    type="radio"
                                    checked={form.PersonasMayoresEdad === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        PersonasMayoresEdad: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Sí</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Mayores de 65 años
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noTerceraEdad"
                                    type="radio"
                                    checked={form.PersonasTerceraEdad === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        PersonasTerceraEdad: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siTerceraEdad"
                                    type="radio"
                                    checked={form.PersonasTerceraEdad === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        PersonasTerceraEdad: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Sí</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Persona jefa de familia
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="HJefeFamilia"
                                    type="radio"
                                    checked={form.PersonaJefaFamilia === "H"}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        PersonasTerceraEdad: "H",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Hombre</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="MJefeFamilia"
                                    type="radio"
                                    checked={form.PersonaJefaFamilia === "M"}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        PersonaJefaFamilia: "M",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Mujer</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 5 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 6
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        VI. SALUD
                      </CardSubtitle>
                      <Row>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Institución afiliado o atención médica:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="AtencionesMedicas"
                              type="select"
                              disabled={loadingCatalogs}
                              multiple
                              value={form.AtencionesMedicas}
                              onChange={(e) =>
                                handleSelectMultiple(
                                  e.target.value,
                                  "AtencionesMedicas"
                                )
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipo_seguro.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>
                              Padece alguna enfermedad o dificultad
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="NoEnfermedades"
                                    type="radio"
                                    checked={tieneEnfermedad === 0}
                                    onChange={() =>
                                      handleChangeTieneEnfermedad(0)
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="SiEnfermedades"
                                    type="radio"
                                    checked={tieneEnfermedad === 1}
                                    onChange={() =>
                                      handleChangeTieneEnfermedad(1)
                                    }
                                  />{" "}
                                  <Label check>Sí</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col xs="12">
                          <FormGroup>
                            <Label>Enfermedades:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="AtencionesMedicas"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              multiple
                              value={form.Enfermedades}
                              onChange={(e) =>
                                handleSelectMultiple(
                                  e.target.value,
                                  "Enfermedades"
                                )
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_enfermedades.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Dificultad para moverse:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadMovilidad"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadMovilidad}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadMovilidad: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_codigos_dificultad.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Dificultad para ver:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadVer"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadVer}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadVer: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_codigos_dificultad.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Dificultad para hablar:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadHablar"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadHablar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadHablar: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_codigos_dificultad.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Dificultad para oír:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadOir"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadOir}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadOir: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_codigos_dificultad.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Dificultad para vestirse:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadVestirse"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadVestirse}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadVestirse: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_codigos_dificultad.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Dificultad para recordar:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadRecordar"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadRecordar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadRecordar: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_codigos_dificultad.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Dificultad para mover brazos o manos:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadBrazos"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadBrazos}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadBrazos: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_codigos_dificultad.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Limitación mental:</Label>
                            {/* <span className="text-danger">*</span> */}
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="DificultadMental"
                              type="select"
                              disabled={
                                loadingCatalogs || tieneEnfermedad === 0
                              }
                              value={form.DificultadMental}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  DificultadMental: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {[
                                { value: 1, label: "Si" },
                                { value: 2, label: "No" },
                              ].map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 6 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 7
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        VII. EDUCACIÓN
                      </CardSubtitle>
                      <Row>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Asiste a la escuela:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="AsisteEscuela"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.AsisteEscuela}
                              onChange={(e) =>
                                handleAsisteEscuela(e.target.value)
                              }
                            >
                              <option value={""}></option>
                              {[
                                { value: 1, label: "Si" },
                                { value: 0, label: "No" },
                              ].map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Nivel:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idNivelEscuela"
                              type="select"
                              value={form.idNivelEscuela}
                              disabled={
                                form.AsisteEscuela === "0" || loadingCatalogs
                              }
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idNivelEscuela: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_niveles_educacion.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Grado:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idGradoEscuela"
                              type="select"
                              value={form.idGradoEscuela}
                              disabled={
                                form.AsisteEscuela === "0" || loadingCatalogs
                              }
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idGradoEscuela: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_grados_educacion.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 7 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 8
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        VIII. INGRESOS
                      </CardSubtitle>
                      <Row>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Durante la semana pasada :
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idActividades"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idActividades}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idActividades: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_actividades.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Prestaciones:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="Prestaciones"
                              type="select"
                              disabled={loadingCatalogs}
                              multiple
                              value={form.Prestaciones}
                              onChange={(e) =>
                                handleSelectMultiple(
                                  e.target.value,
                                  "Prestaciones"
                                )
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_prestaciones.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Durante el mes pasado, ¿Cuál fue el ingreso total
                              de su hogar?
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="IngresoTotalMesPasado"
                              type="number"
                              value={form.IngresoTotalMesPasado}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  IngresoTotalMesPasado: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿Cuál es la cantidad total mensual que recibe su
                              hogar por pensión, jubilación o programas
                              gubernamentales?
                            </Label>
                            {/* <span className="text-danger">*</span> */}
                            <Input
                              name="PensionMensual"
                              type="number"
                              value={form.PensionMensual}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PensionMensual: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿En su hogar cuánto dinero proveniente de otros
                              países recibe mensualmente?
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="IngresoOtrosPaises"
                              type="number"
                              value={form.IngresoOtrosPaises}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  IngresoOtrosPaises: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 8 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 9
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        IX. GASTO
                      </CardSubtitle>
                      <Row>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Gasto en alimentos y bebidas:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="GastoAlimentos"
                              type="number"
                              value={form.GastoAlimentos}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastoAlimentos: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>

                            <Input
                              name="PeriodicidadAlimentos"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadAlimentos}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadAlimentos: e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Compra o reparación de vestido o calzado:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="GastoVestido"
                              type="number"
                              value={form.GastoVestido}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastoVestido: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="PeriodicidadVestido"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadVestido}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadVestido: e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Gastos en educación:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="GastoEducacion"
                              type="number"
                              value={form.GastoEducacion}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastoEducacion: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="PeriodicidadEducacion"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadEducacion}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadEducacion: e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Gastos en medicinas:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="GastoMedicinas"
                              type="number"
                              value={form.GastoMedicinas}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastoMedicinas: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="PeriodicidadEducacion"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadMedicinas}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadMedicinas: e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Gastos en consultas médicas:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="GastosConsultas"
                              type="number"
                              value={form.GastosConsultas}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastosConsultas: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="PeriodicidadConsultas"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadConsultas}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadConsultas: e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Gastos en combustibles:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="GastosCombustibles"
                              type="number"
                              value={form.GastosCombustibles}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastosCombustibles: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="PeriodicidadCombustibles"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadCombustibles}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadCombustibles: e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Gastos en servicios básicos:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="GastosServiciosBasicos"
                              type="number"
                              value={form.GastosServiciosBasicos}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastosServiciosBasicos: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="PeriodicidadServiciosBasicos"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadServiciosBasicos}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadServiciosBasicos: e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Gastos en servicios de recreación:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="GastosServiciosRecreacion"
                              type="number"
                              value={form.GastosServiciosRecreacion}
                              disabled={loadingCatalogs}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  GastosServiciosRecreacion: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Periodicidad:
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="PeriodicidadServiciosBasicos"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.PeriodicidadServiciosRecreacion}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PeriodicidadServiciosRecreacion:
                                    e.target.value,
                                })
                              }
                            >
                              {periodos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 9 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 10
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        X. ALIMENTACIÓN
                      </CardSubtitle>
                      <Row>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Poca variedad de alimentos
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noVarieradAlimentos"
                                    type="radio"
                                    checked={form.AlimentacionPocoVariada === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        AlimentacionPocoVariada: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siVarieradAlimentos"
                                    type="radio"
                                    checked={form.AlimentacionPocoVariada === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        AlimentacionPocoVariada: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Comió menos de lo que debía
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noComioMenos"
                                    type="radio"
                                    checked={form.ComioMenos === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ComioMenos: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siComioMenos"
                                    type="radio"
                                    checked={form.ComioMenos === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ComioMenos: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Disminucion de cantidad
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noDisminucionComida"
                                    type="radio"
                                    checked={form.DisminucionComida === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        DisminucionComida: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siDisminucionComida"
                                    type="radio"
                                    checked={form.DisminucionComida === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        DisminucionComida: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Se acostó con hambre
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noDurmioHambre"
                                    type="radio"
                                    checked={form.DurmioHambre === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        DurmioHambre: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siDurmioHambre"
                                    type="radio"
                                    checked={form.DurmioHambre === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        DurmioHambre: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Dejó de comer todo un día
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noDejoComer"
                                    type="radio"
                                    checked={form.DejoComer === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        DejoComer: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siDejoComer"
                                    type="radio"
                                    checked={form.DejoComer === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        DejoComer: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 10 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 11
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        XI. VIVIENDA
                      </CardSubtitle>
                      <Row>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Número de personas viviendo en el hogar:{" "}
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="PersonasHogar"
                              type="number"
                              value={form.PersonasHogar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  PersonasHogar: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Número de cuartos en el hogar:{" "}
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="CuartosHogar"
                              type="number"
                              value={form.CuartosHogar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CuartosHogar: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              La vivienda que habita es:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoVivienda"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoVivienda}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoVivienda: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_viviendas.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              La mayor parte del piso de su vivienda es de:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoPiso"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoPiso}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoPiso: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_pisos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              La mayor parte de los muros de su vivienda son de:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoParedes"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoParedes}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoParedes: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_muros.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              La mayor parte de los muros de su vivienda son de:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoTecho"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoTecho}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoTecho: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_techos.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              En su vivienda tienen:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoAgua"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoAgua}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoAgua: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_agua.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              La vivienda tiene drenaje o desagüe conectado a:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoDrenaje"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoDrenaje}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoDrenaje: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_drenajes.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              En la vivienda la luz electrica la obtienen:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoLuz"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoLuz}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoLuz: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_luz.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              El combustible que más usan al cocinar es:{" "}
                              {/* <span className="text-danger">*</span> */}
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoCombustible"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.idTipoCombustible}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idTipoCombustible: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.cat_tipos_combustibles.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="12" style={{ textAlign: "center" }}>
                          <CardText>
                            En su vivienda tienen y funciona:{" "}
                          </CardText>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Refrigerador:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noRefrigerador"
                                    type="radio"
                                    checked={form.Refrigerador === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Refrigerador: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siRefrigerador"
                                    type="radio"
                                    checked={form.Refrigerador === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Refrigerador: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Lavadora:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noLavadora"
                                    type="radio"
                                    checked={form.Lavadora === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Lavadora: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siLavadora"
                                    type="radio"
                                    checked={form.Lavadora === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Lavadora: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Computadora, laptop o tablet:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noComputadora"
                                    type="radio"
                                    checked={form.Computadora === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Computadora: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siComputadora"
                                    type="radio"
                                    checked={form.Computadora === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Computadora: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Estufa o parrilla de gas:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noEstufa"
                                    type="radio"
                                    checked={form.Estufa === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Estufa: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siEstufa"
                                    type="radio"
                                    checked={form.Estufa === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Estufa: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Calentador de agua o boiler:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noCalentador"
                                    type="radio"
                                    checked={form.Calentador === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Calentador: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siCalentador"
                                    type="radio"
                                    checked={form.Calentador === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Calentador: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Calentador solar:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noCalentadorSolar"
                                    type="radio"
                                    checked={form.CalentadorSolar === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        CalentadorSolar: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siCalentadorSolar"
                                    type="radio"
                                    checked={form.CalentadorSolar === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        CalentadorSolar: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Televisión digital:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noTelevision"
                                    type="radio"
                                    checked={form.Television === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Television: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siTelevision"
                                    type="radio"
                                    checked={form.Television === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Television: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Internet:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noInternet"
                                    type="radio"
                                    checked={form.Internet === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Internet: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siInternet"
                                    type="radio"
                                    checked={form.Internet === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Internet: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Teléfono móvil o celular:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noTieneTelefono"
                                    type="radio"
                                    checked={form.TieneTelefono === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        TieneTelefono: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siTieneTelefono"
                                    type="radio"
                                    checked={form.TieneTelefono === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        TieneTelefono: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Tinaco:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noTinaco"
                                    type="radio"
                                    checked={form.Tinaco === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Tinaco: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siTinaco"
                                    type="radio"
                                    checked={form.Tinaco === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Tinaco: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Si</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 11 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 12
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        XII. PERCEPCIÓN
                      </CardSubtitle>
                      <Row>
                        <Col sm="12" xs="12">
                          <FormGroup>
                            <Label>
                              En términos de delincuencia, considera que vivir
                              en su colonia o localidad es:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noColoniaSegura"
                                    type="radio"
                                    checked={form.ColoniaSegura === 0}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ColoniaSegura: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Insegura</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siColoniaSegura"
                                    type="radio"
                                    checked={form.ColoniaSegura === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ColoniaSegura: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Segura</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 12 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 13
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        XIII. ARCHIVOS DE EVIDENCIA (CARPETA DIGITAL)
                      </CardSubtitle>
                      <Row>
                        <ListadoArchivos
                          files={form.Files}
                          archivosClasificacion={form.ArchivosClasificacion}
                          catalogClasificacion={catalogs.archivos_clasificacion}
                          handleChangeArchivos={handleChangeArchivos}
                          handleChangeClasificacion={handleChangeClasificacion}
                          handleDeleteFile={handleDeleteFile}
                          loadingCatalogs={loadingCatalogs}
                          onlyForView={form.ListaParaEnviar === 1}
                        />
                        {errors.error && errors.seccion === 13 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col sm="12" style={{ textAlign: "center" }}>
                          <Link to="/cedula-list">
                            <Button color="warning">Cancelar</Button>
                          </Link>
                          {form.ListaParaEnviar === 0 ? (
                            <>
                              {isForEdit && menu.Eliminar === 1 && (
                                <Button
                                  color="danger"
                                  onClick={() => deleteAlert()}
                                >
                                  Eliminar
                                </Button>
                              )}

                              {menu.Agregar === 1 && (
                                <Button onClick={() => save()}>Guardar</Button>
                              )}
                              {menu.Agregar === 1 && (
                                <Button
                                  color="primary"
                                  onClick={() => saveAlert()}
                                >
                                  Guardar y lista para enviar
                                </Button>
                              )}
                            </>
                          ) : (
                            <Button color="success" onClick={() => {}}>
                              Enviar a ventanilla
                            </Button>
                          )}
                          {isLoadingForm && <Spinner size="sm" />}
                        </Col>
                        <Col
                          sm="12"
                          style={{ textAlign: "center", color: "red" }}
                        >
                          {form.ListaParaEnviar === 1 && (
                            <CardText>
                              La cedula está lista para enviarse, no puede ser
                              editada
                            </CardText>
                          )}
                          {errors.error && (
                            <CardText>
                              Existen errores, por favor verifique el formulario
                            </CardText>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default CedulaForm;

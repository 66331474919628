import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { getVars } from "../../GlobalStorage";
import Sidebar from "../../components/Sidebar";
import { URL_GENERAL } from "../../data/consts";
import { CircleSpinner } from "react-spinners-kit";
import SolicitudProyecto from "./SolicitudProyecto";
import EjecutarApi from "../../Consultas/Consultas";
import Pagination from "../../components/pagination";
import Notifications, { notify } from "react-notify-toast";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import FilesProyectos from "../../components/Archivos/FileListProyectos";
import PluginFilterProyectos from "../../components/PluginFilterProyectos";

const syledRowsTable = {
  fontSize: "10px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
};

const ProyectosProductivos = (props) => {
  const [anio, setAnio] = useState(new Date().getFullYear());
  // const [anio, setAnio] = useState(2023);
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [TotalSolicitudes, setTotalSolicitudes] = useState(0);
  const [TotalPendientes, setTotalPendientes] = useState(0);
  const [TotalValidadas, setTotalValidadas] = useState(0);
  // const [TotalObservadas, setTotalObservadas] = useState(0);
  //Filtro
  const [filtro, setFiltro] = useState([{ id: ".Ejercicio", value: anio }]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isSolicitud, setIsSolicitud] = useState(false);
  const [isArchivos, setIsArchivos] = useState(false);
  const [solicitud, setSolicitud] = useState(null);
  const [archivo, setArchivo] = useState(null);
  const [flagSolicitud, setFlagSolicitud] = useState(false);
  const [urlSolicitud, setUrlSolicitud] = useState("");
  const columns = [
    {
      Header: "Acciones",
      minWidth: 140,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio",
      minWidth: 70,
      id: "FolioSolicitud",
      accessor: (d) => d.FolioSolicitud,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "FolioSDES",
      minWidth: 70,
      id: "FolioSDES",
      accessor: (d) => d.FolioSDES,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre Completo",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Region",
      minWidth: 65,
      id: "RegionM",
      accessor: (d) => d.Region,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 85,
      id: "MunicipioVive",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Dirección",
      minWidth: 300,
      id: "Direccion",
      accessor: (d) => d.Direccion,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Celular",
      minWidth: 120,
      id: "Celular",
      accessor: (d) => d.Celular,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Expediente Completo",
      minWidth: 155,
      id: "Expediente",
      accessor: (d) => d.Expediente,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CreadoPor",
      minWidth: 120,
      id: "CreadoPor",
      accessor: (d) => d.CreadoPor,
      filterAll: false,
      style: syledRowsTable,
      headerStyle: { fontSize: "10px" },
    },
  ];

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleShowFiles = (cedula) => {
    setIsArchivos(true);
    cedula.idPrograma = 2;
    cedula.menu = menu;
    setArchivo(cedula);
  };

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 33);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
    });

    setMenuLoaded(true);
  }, [userData, history]);

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "proyectos/getSolicitudes", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);

          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.NumExt == null) item.NumExt = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.Calle} ${item.NumExt} Col. ${item.Colonia} ${item.CP}`,
                Expediente:
                  item.ExpedienteCompleto === 1 ? (
                    <>
                      <span
                        className="spinner-grow text-success"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                      Archivos Completos
                    </>
                  ) : (
                    <>
                      <span
                        className="spinner-grow text-danger"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                      Faltan Archivos
                    </>
                  ),
                Acciones: (
                  <>
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        padding: 0,
                        margin: "auto auto",
                      }}
                    >
                      <Col style={{ padding: 0 }}>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => sendDataSolicitud(item)}
                        >
                          <i
                            className="fa fa-2x fa-address-card-o"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => handleShowFiles(item)}
                        >
                          <i
                            className="fa fa-2x fa-files-o"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const sendDataSolicitud = (item) => {
    setSolicitud(item);
    setIsSolicitud(!isSolicitud);
  };

  const setFilter = (filtered) => {
    filtered.programa = 2;
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);

    EjecutarApi(filtered, "proyectos/getSolicitudes", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.NoExtVive == null) item.NoExtVive = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.Calle} ${item.NumExt} Col. ${item.Colonia} ${item.CP}`,
                Expediente:
                  item.ExpedienteCompleto === 1 ? (
                    <>
                      <span
                        className="spinner-grow text-success"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                      Archivos Completos
                    </>
                  ) : (
                    <>
                      <span
                        className="spinner-grow text-danger"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                      Faltan Archivos
                    </>
                  ),
                Acciones: (
                  <>
                    <Row>
                      <Col>
                        <>
                          <Button
                            color="primary"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => sendDataSolicitud(item)}
                          >
                            Solicitud
                          </Button>
                        </>
                      </Col>
                      <Col>
                        <Button
                          color="warning"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => handleShowFiles(item)}
                        >
                          Archivos
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({ Ejercicio: anio }, "proyectos/getTotalSolicitudes", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalSolicitudes(res.capturadas);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en al obtener el total de solicitudes! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, [isSolicitud, isArchivos, anio]);

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({ Ejercicio: anio }, "proyectos/getTotalPendientes", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalPendientes(res.pendientes);
        }
      })
      .catch((e) => {
        notify.show(
          `Error al obtener el total de pendientes! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, [isSolicitud, isArchivos, anio]);

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({ Ejercicio: anio }, "proyectos/getTotalValidadas", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalValidadas(res.validadas);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la obtencion de datos! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, [isArchivos]);

  const btnExportar = () => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL + `proyectos/getSolicitudesReporte?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            {!isSolicitud && !isArchivos ? (
              <>
                <Modal
                  size="lg"
                  isOpen={flagSolicitud}
                  toggle={() => setFlagSolicitud(!flagSolicitud)}
                >
                  <ModalBody>
                    <Row>
                      <object
                        type="application/pdf"
                        aria-labelledby={"Solicitud"}
                        data={urlSolicitud}
                        width="100%"
                        height="500"
                      ></object>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setFlagSolicitud(false);
                        setUrlSolicitud("");
                      }}
                    >
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>
                <Row>
                  <Col lg="4" md="4" sm="12">
                    <Card className="card-stats" style={{ padding: "1rem" }}>
                      <CardBody style={{ padding: 2 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            width: "100%",
                            margin: "auto auto",
                          }}
                        >
                          <Col
                            md="2"
                            xs="2"
                            style={{
                              textAlign: "center",
                              padding: 0,
                            }}
                          >
                            <span
                              style={{
                                color: "#2471a3",
                              }}
                            >
                              <i
                                className="fa fa-files-o fa-3x"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Col>
                          <Col md="6" xs="6" style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#2471a3",
                                margin: "auto auto",
                              }}
                            >
                              Solicitudes Capturadas
                            </p>
                          </Col>
                          <Col md="4" sm="4" style={{ textAlign: "center" }}>
                            <span
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            >
                              {new Intl.NumberFormat("en-EN").format(
                                TotalSolicitudes
                              )}
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="4" md="4" sm="12">
                    <Card className="card-stats" style={{ padding: "1rem" }}>
                      <CardBody style={{ padding: 2 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            width: "100%",
                            margin: "auto auto",
                          }}
                        >
                          <Col
                            md="2"
                            xs="2"
                            style={{
                              textAlign: "center",
                              padding: 0,
                            }}
                          >
                            <span
                              style={{
                                color: "#f39c12",
                              }}
                            >
                              <i
                                className="fa fa-eye fa-3x"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Col>
                          <Col md="6" xs="6" style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#f39c12",
                                margin: "auto auto",
                              }}
                            >
                              Solicitudes Por Validar
                            </p>
                          </Col>
                          <Col md="4" sm="4" style={{ textAlign: "center" }}>
                            <span
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            >
                              {new Intl.NumberFormat("en-EN").format(
                                TotalPendientes
                              )}
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="4" md="4" sm="12">
                    <Card className="card-stats" style={{ padding: "1rem" }}>
                      <CardBody style={{ padding: 2 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            width: "100%",
                            margin: "auto auto",
                          }}
                        >
                          <Col
                            md="2"
                            xs="2"
                            style={{
                              textAlign: "center",
                              padding: 0,
                            }}
                          >
                            <span
                              style={{
                                color: "#229954",
                              }}
                            >
                              <i
                                className="fa fa-thumbs-o-up fa-3x"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Col>
                          <Col md="6" xs="6" style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#229954",
                                margin: "auto auto",
                              }}
                            >
                              Solicitudes Validadas
                            </p>
                          </Col>
                          <Col md="4" sm="4" style={{ textAlign: "center" }}>
                            <span
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            >
                              {new Intl.NumberFormat("en-EN").format(
                                TotalValidadas
                              )}
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="8">
                            <CardTitle tag="h6">
                              Listado de Solicitudes
                            </CardTitle>
                          </Col>
                          <Col md="2">
                            {menu.Agregar === 1 ? (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  sendDataSolicitud(null);
                                }}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-user-plus fa-2x" />
                                </span>
                                Capturar
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col md="2">
                            {menu.Exportar === 1 ? (
                              <>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={btnExportar}
                                >
                                  <span className="btn-label">
                                    <i className="fa fa-file-excel-o fa-2x" />
                                  </span>
                                  Exportar
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {loadingCedulaList && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loadingCedulaList}
                          />
                        )}
                        <Col lg="12" md="12">
                          {menuLoaded && (
                            <ReactTable
                              size="sm"
                              data={dataCedulaList}
                              noDataText="No Existen Datos a Mostrar!"
                              manual
                              columns={columns}
                              className="-striped -highlight primary-pagination"
                              loading={loadingCedulaList}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={pageOptions.pageSize}
                              total={total}
                              page={pageOptions.page}
                              pages={Math.floor(total / pageOptions.pageSize)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={fetchData}
                            />
                          )}
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : isSolicitud && !isArchivos ? (
              <SolicitudProyecto
                data={solicitud}
                backSolicitud={() => {
                  setIsSolicitud(!isSolicitud);
                }}
                archivos={(data, menu) => {
                  data.idPrograma = 3;
                  data.menu = menu;
                  setIsSolicitud(!isSolicitud);
                  setArchivo(data);
                  setIsArchivos(true);
                }}
              />
            ) : (
              <FilesProyectos
                data={archivo}
                backSolicitud={() => {
                  setIsArchivos(!isArchivos);
                }}
                solicitud={(data) => {
                  setIsArchivos(!isArchivos);
                  setSolicitud(data);
                  setIsSolicitud(!isSolicitud);
                }}
              />
            )}
          </div>
          <PluginFilterProyectos
            sendFiltro={setFilter}
            anio={anio}
            setAnio={setAnio}
            clasess="dropdowns"
          />
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default ProyectosProductivos;

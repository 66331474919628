import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import EjecutarApi from "../Consultas/Consultas";
import { RotateSpinner, ImpulseSpinner } from "react-spinners-kit";
import { getVars } from "../GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import ReactTable from "react-table";

const ComerciosAdd = (props) => {
  const token = getVars("Token").token;
  const [flagSet, setFlagSet] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [giros, setGiros] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [data, setData] = useState([]);
  const [totalColonias, setTotalColonias] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showDatosTarjeta, setShowDatosTarjeta] = useState(false);
  const [flagEscogerColonia, setFlagEscogerColonia] = useState(false);
  const [loadingCURP, setloadingCURP] = useState(false);
  const [flagbtnGuardar, setflagbtnGuardar] = useState(false);
  const [comercio, setComercio] = useState({
    RFC: "",
    NombreEmpresa: "",
    Nombre: "",
    Paterno: "",
    Materno: "",
    TelNegocio: "",
    TelCasa: "",
    Celular: "",
    idMunicipio: "",
    CP: "",
    Calle: "",
    NumInt: "",
    NumExt: "",
    Colonia: "",
    Correo: "",
    Latitude: "",
    Longitude: "",
    FechaInscripcion: "",
    idStatus: 1,
    HorarioAtencion: "",
    idTipoNegocio: "",
    Giros: [],
    Cobrador: {
      CURP: "",
      Nombre: "",
      Paterno: "",
      Materno: "",
    },
    Banco: "",
    CLABE: "",
    NumTarjeta: "",
    QuiereTransferencia: "",
  });
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 1000,
    sorted: [],
    filtered: [],
  };

  const Spinenerstyle = {
    height: "50pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  useEffect(() => {
    const datos = {
      page: 0,
      tipo: "and",
      pageSize: 1000,
      sorted: [],
      filtered: [],
    };

    EjecutarApi(datos, "getMunicipiosET", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMunicipios(
            res.data.map((municipio) => {
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    EjecutarApi(datos, "getNegociosTipo", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTipos(
            res.data.map((tipo) => {
              return {
                value: tipo["id"],
                label: tipo["Tipo"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    EjecutarApi(datos, "getGiros", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setGiros(
            res.data.map((giro_) => {
              return {
                value: giro_["id"],
                label: giro_["Giro"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);

  const setNegocio = () => {
    //setComercio({ ...comercio, Colonia: (comercio.Colonia !== "" && comercio.Colonia !== "otro") ? comercio.Colonia : otraColonia });

    setFlagSet(true);

    if (comercio.idTipoNegocio.length < 1) {
      setFlagSet(false);
      notify.show(
        "Error: La clasificacion del comercio es requerido.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    if (comercio.Giros.length < 1) {
      setFlagSet(false);
      notify.show("Error: Giros es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (comercio.NombreEmpresa.length < 3) {
      setFlagSet(false);
      notify.show(
        "Error: El Nombre del comercio es requerido.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (comercio.NombreEmpresa.length > 119) {
      setFlagSet(false);
      notify.show(
        "Error: El nombre del comercio es muy largo.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (comercio.FechaInscripcion.length < 3) {
      setFlagSet(false);
      notify.show(
        "Error: La fecha de firma del convenio es requerida.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (comercio.RFC.length > 0) {
      if (comercio.RFC.length > 13) {
        setFlagSet(false);
        notify.show(
          "Error: El RFC debe tener 12 caracteres o 13 caractes.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }
    if (comercio.HorarioAtencion.length > 0) {
      if (comercio.RFC.length > 119) {
        setFlagSet(false);
        notify.show(
          "Error: El horario de atención es muy largo.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }

    //TARJETA
    if (showDatosTarjeta) {
      if (comercio.Banco.length < 3) {
        setFlagSet(false);
        notify.show(
          "Error: El nombre del banco de la cuenta es requerido.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
      if (comercio.Banco.length > 78) {
        setFlagSet(false);
        notify.show(
          "Error: El nombre del banco de la cuenta es requerido.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
      if (comercio.CLABE.length !== 18) {
        setFlagSet(false);
        notify.show(
          "Error: La clabe interbancaria es requerida y debe tener 18 digitos",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
      if (comercio.NumTarjeta.length !== 16) {
        setFlagSet(false);
        notify.show(
          "Error: EL numero de tarjeta es requerida y debe tener 16 digitos",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
      comercio.QuiereTransferencia = 1;
    }

    if (comercio.idMunicipio.length < 1) {
      setFlagSet(false);
      notify.show("Error: El municipio es requerido.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (comercio.Colonia.length < 3) {
      setFlagSet(false);
      notify.show("Error: La colonia es requerida.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (comercio.Colonia.length > 118) {
      setFlagSet(false);
      notify.show(
        "Error: El nombre de la colonia es demasiado largo.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    if (comercio.CP.length !== 5) {
      setFlagSet(false);
      notify.show(
        "Error: Codigo Postal es requerido. El codigo postal debe tener 5 caracteres.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    if (comercio.Calle.length < 3) {
      setFlagSet(false);
      notify.show("Error: La calle es requerida.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (comercio.Calle.length > 118) {
      setFlagSet(false);
      notify.show("Error: La calle es demaciado larga.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (comercio.NumExt.length < 1) {
      setFlagSet(false);
      notify.show("Error: El número exterior es requerido.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (comercio.NumExt.length > 43) {
      setFlagSet(false);
      notify.show(
        "Error: El número exterior es demasiado largo.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (comercio.NumInt.length !== 0) {
      if (comercio.NumInt.length > 43) {
        setFlagSet(false);
        notify.show(
          "Error: El número interior es demasiado largo.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }

    if (!isLatitude(comercio.Latitude) && comercio.Latitude.length !== 0) {
      setFlagSet(false);
      notify.show(
        "Error: La latitud es requerida y con un formato valido.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      if (comercio.Latitude.length > 43) {
        setFlagSet(false);
        notify.show("Error: La latitud es demasiado largo.", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      }
      return true;
    }

    if (!isLongitude(comercio.Longitude) && comercio.Longitude.length !== 0) {
      setFlagSet(false);
      notify.show(
        "Error: La longitud es requerida y con un formato valido.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      if (comercio.Longitude.length > 43) {
        setFlagSet(false);
        notify.show("Error: La longitud es demasiado largo.", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      }
      return true;
    }

    if (comercio.Nombre.length < 3) {
      setFlagSet(false);
      notify.show("Error: El nombre es requerido.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (comercio.Nombre.length > 63) {
      setFlagSet(false);
      notify.show("Error: El nombre es demasiado largo.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (comercio.Paterno.length < 3) {
      setFlagSet(false);
      notify.show("Error: El apellido paterno es requerido.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (comercio.Paterno.length > 63) {
      setFlagSet(false);
      notify.show(
        "Error: El apellido paterno es demasiado largo.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (comercio.Materno.length < 3) {
      setFlagSet(false);
      notify.show("Error: El apellido materno es requerido.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (comercio.Materno.length > 63) {
      setFlagSet(false);
      notify.show(
        "Error: El apellido materno es demasiado largo.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    if (comercio.Correo.length !== 0) {
      if (!validateEmail(comercio.Correo)) {
        setFlagSet(false);
        notify.show(
          "Error: El correo debe ser por ejemplo [email@ejemplo.com].",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
      if (comercio.Correo.length > 118) {
        setFlagSet(false);
        notify.show("Error: El correo es demasiado largo.", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
    }

    if (comercio.Celular.length !== 0) {
      if (comercio.Celular.length > 30) {
        setFlagSet(false);
        notify.show(
          "Error: El telefono celular es demasiado largo.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }

    if (comercio.TelNegocio.length !== 0) {
      if (comercio.TelNegocio.length > 30) {
        setFlagSet(false);
        notify.show(
          "Error: El telefono celular es demasiado largo.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }

    if (comercio.TelCasa.length !== 0) {
      if (comercio.TelCasa.length > 30) {
        setFlagSet(false);
        notify.show(
          "Error: El telefono de casa es demasiado largo.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }

    //COBRADOR
    if (!comercio.Cobrador.CURP) {
      setFlagSet(false);
      notify.show(
        "Error: La CURP del cobrador es requerida y valida.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (comercio.Cobrador.CURP.length > 20) {
      setFlagSet(false);
      notify.show("Error: La CURP es demasiado larga.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    /*
        if (comercio.Cobrador.Nombre.length < 2) {
            setFlagSet(false)
            notify.show('Error: El nombre del cobrador es requerido.', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (comercio.Cobrador.Nombre.length > 63) {
            setFlagSet(false)
            notify.show('Error: El nombre del cobrador es demasiado largo.', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (comercio.Cobrador.Paterno.length < 4) {
            setFlagSet(false)
            notify.show('Error: El apellido paterno del cobrador es requerido.', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (comercio.Cobrador.Paterno.length > 63) {
            setFlagSet(false)
            notify.show('Error: El apellido paterno del cobrador es demasiado largo.', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (comercio.Cobrador.Materno.length < 4) {
            setFlagSet(false)
            notify.show('Error: El apellido materno del cobrador es requerido', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (comercio.Cobrador.Materno.length > 63) {
            setFlagSet(false)
            notify.show('Error: El apellido materno del cobrador es demasiado largo.', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        */

    const dataComercio = {
      ...comercio,
      QuiereTransferencia: showDatosTarjeta ? 1 : 0,
      Cobrador: [comercio.Cobrador],
    };

    console.log("BANDERA#######", JSON.stringify(dataComercio));
    //return true;
    comercio.Cobrador = [comercio.Cobrador];
    EjecutarApi(comercio, "setNegocios", token)
      .then(function (res) {
        setFlagSet(false);
        setflagbtnGuardar(true);
        notify.show("Registro Exitoso!", "custom", 5000, {
          background: "#18c97d",
          text: "#FFFFFF",
        });
        setComercio({ ...comercio, Cobrador: comercio.Cobrador[0] });
        return res;
      })
      .catch((e) => {
        setComercio({ ...comercio, Cobrador: comercio.Cobrador[0] });
        console.log(e.errors);
        setFlagSet(false);
        notify.show(
          `Error en el registro del comercio. ${e.errors.Clave}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });

    setFlagSet(false);
  };

  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  function getCURP(curp) {
    setloadingCURP(true);
    console.log("=> Buscar CURP");
    if (!curp) {
      setloadingCURP(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    if (curp.length != 18) {
      setloadingCURP(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    const CURP = curp.toUpperCase();
    var sexo_obtenido;
    console.log("=> Buscar CURP: ", CURP);
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/A94A3BAC-C920-4290-82FD-C1F2A4044DBB`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        setloadingCURP(false);
        console.log("=> Resultados CURP", dataRS);
        if (dataRS.Mensaje == "OK") {
          //aqui

          setComercio({
            ...comercio,
            Cobrador: {
              ...comercio.Cobrador,
              Nombre: dataRS.Resultado.nombres,
              Paterno: dataRS.Resultado.apellido1,
              Materno: dataRS.Resultado.apellido2,
            },
          });
        } else {
          setloadingCURP(false);
          if (
            dataRS.Mensaje === "La CURP no se encuentra en la base de datos"
          ) {
            console.log("La CURP no se encuentra en la base de datos");
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              text: "#FFFFFF",
            });
          } else if (
            dataRS.Mensaje ===
            "El campo CURP: No cumple con el formato especificado."
          ) {
            console.log(
              "El campo CURP: No cumple con el formato especificado."
            );
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              text: "#FFFFFF",
            });
          } else {
            console.log("El servicio no esta disponible.");
          }
          return true;
        }
        console.log("=> Resultados CURP", dataRS);
      })
      .catch((err) => {
        setloadingCURP(false);
        console.log("=>Error en curo: ", err);
      });

    console.log("=> Resultados CURP", comercio.Cobrador);
  }
  function isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }

  function isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  console.log("STATE - STATE - STATE:", comercio);
  const mini_table = (
    <>
      <ReactBSAlert
        style={{ display: "block", marginTop: "-350pt" }}
        //title="Here's a message!"
        onConfirm={() => setFlagEscogerColonia(false)}
        onCancel={() => setFlagEscogerColonia(false)}
        confirmBtnBsStyle="info"
      >
        <Card>
          <CardBody>
            <ReactTable
              data={data}
              noDataText="Sin información"
              columns={[
                {
                  Header: "Colonia",
                  minWidth: 10,
                  id: "Colonia",
                  accessor: (d) => d.Colonia,
                  filterAll: false,
                },
              ]}
              defaultPageSize={10}
              filterable
              className={"-striped -highlight primary-pagination"}
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalColonias}
              pages={Math.floor(totalColonias / 10)}
              // previousText="Anterior"
              // nextText="Siguiente"
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    setComercio({
                      ...comercio,
                      Colonia: rowInfo.original.Colonia,
                      CP: rowInfo.original.data.d_codigo,
                    });
                    setFlagEscogerColonia(false);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setLoading(true);
                const token = getVars("Token").token;

                var array_filtros = [];
                array_filtros.push({ id: "c_estado", value: 11 });
                array_filtros.push({
                  id: "c_mnpio",
                  value: comercio.idMunicipio,
                });
                if (stateComp.filtered.length !== 0) {
                  array_filtros.push({
                    id: "d_asenta",
                    value: stateComp.filtered[0].value,
                  });
                }
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "d_asenta", desc: false }],
                  filtered: array_filtros,
                };

                EjecutarApi(datax, "getCP", token)
                  .then(function (res) {
                    return res;
                  })
                  .then((res) => {
                    setLoading(false);
                    setTotalColonias(res.total);
                    setData(
                      res.data.map((prop, key) => {
                        return {
                          Colonia: prop.d_asenta,
                          data: prop,
                        };
                      })
                    );
                  })
                  .catch((e) => {
                    console.log("=Error[getCP]: ", e);
                    setLoading(false);
                    notify.show(
                      `Error al obtener colonias: ${e.errors}`,
                      "custom",
                      5000,
                      {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                      }
                    );
                  });
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Link to="comercios">
                      <Button color="success">Regresar</Button>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <Form className="form-horizontal" id="TypeValidation">
                      <Notifications />
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">
                            Inscripción de Comercio
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <CardHeader>
                            <CardTitle tag="h5">Datos del Comercio</CardTitle>
                          </CardHeader>
                          <Row>
                            {tipos.length > 0 ? (
                              <>
                                <Label sm="2">
                                  Clasificación del Comercio:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="4">
                                  <Select
                                    classNamePrefix="react-select"
                                    id="status"
                                    onChange={(event) => {
                                      setComercio({
                                        ...comercio,
                                        idTipoNegocio: event.value,
                                      });
                                    }}
                                    options={tipos}
                                    placeholder="seleccione la clasificación de comercio"
                                  />
                                </Col>
                              </>
                            ) : (
                              <Col sm="2" style={Spinenerstyle}>
                                <RotateSpinner
                                  color={"#1261ff"}
                                  size={30}
                                  loading={true}
                                />
                              </Col>
                            )}
                            {giros.length > 0 ? (
                              <>
                                <Label sm="2">
                                  Giro(s):<span className="text-danger">*</span>
                                </Label>
                                <Col sm="4">
                                  <Select
                                    id="example-multiple-selected"
                                    isMulti="true"
                                    isSearchable
                                    options={giros}
                                    onChange={(event, i) => {
                                      if (event) {
                                        setComercio({
                                          ...comercio,
                                          Giros: event.map((dato) => {
                                            return dato.value;
                                          }),
                                        });
                                      } else {
                                        setComercio({
                                          ...comercio,
                                          Giros: comercio.Giros.map((dato) => {
                                            if (i.removedValue.value !== dato) {
                                              return dato.value;
                                            }
                                          }),
                                        });
                                      }
                                    }}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                  />
                                </Col>
                              </>
                            ) : (
                              <Col sm="2" style={Spinenerstyle}>
                                <RotateSpinner
                                  color={"#1261ff"}
                                  size={30}
                                  loading={true}
                                />
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Label sm="2">
                              Nombre del Comercio:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  id="nombreEmpresa"
                                  type="text"
                                  value={comercio.NombreEmpresa}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      NombreEmpresa:
                                        event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Fecha Firma Convenio:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <ReactDatetime
                                  id="fechaInscripcion"
                                  name="fechaInscripcion"
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat={false}
                                  closeOnSelect
                                  onChange={(e) =>
                                    setComercio({
                                      ...comercio,
                                      FechaInscripcion: moment(e._d).format(
                                        "YYYY-MM-DD"
                                      ),
                                    })
                                  }
                                  inputProps={{ className: "form-control" }}
                                />
                              </FormGroup>
                            </Col>

                            <Label sm="2">RFC</Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  name="rfc"
                                  type="text"
                                  value={comercio.RFC}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      RFC: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Horario de Atención:</Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  id="hora"
                                  type="textarea"
                                  value={comercio.HorarioAtencion}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      HorarioAtencion:
                                        event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Label sm="6">
                            Acepta Transferencia Interbancaria
                          </Label>
                          <Switch
                            sm="6"
                            defaultValue={false}
                            onColor="default"
                            offColor="default"
                            onChange={() => {
                              setShowDatosTarjeta(!showDatosTarjeta);
                            }}
                          />
                          {showDatosTarjeta ? (
                            <>
                              <Row>
                                <Label sm="2">
                                  Banco de la Cuenta:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="10">
                                  <FormGroup>
                                    <Input
                                      name="Banco"
                                      type="text"
                                      value={comercio.Banco}
                                      onChange={(event) =>
                                        setComercio({
                                          ...comercio,
                                          Banco:
                                            event.target.value.toUpperCase(),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">
                                  CLABE Interbancaria:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="10">
                                  <FormGroup>
                                    <Input
                                      name="CLABE"
                                      type="text"
                                      value={comercio.CLABE}
                                      onChange={(event) =>
                                        setComercio({
                                          ...comercio,
                                          CLABE:
                                            event.target.value.toUpperCase(),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">
                                  Número Tarjeta:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="10">
                                  <FormGroup>
                                    <Input
                                      name="NumTarjeta"
                                      type="text"
                                      value={comercio.NumTarjeta}
                                      onChange={(event) =>
                                        setComercio({
                                          ...comercio,
                                          NumTarjeta:
                                            event.target.value.toUpperCase(),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                          <CardHeader>
                            <CardTitle tag="h5">
                              Domicilio del Comercio
                            </CardTitle>
                          </CardHeader>
                          <Row>
                            {municipios.length !== 0 ? (
                              <>
                                <Label sm="2">
                                  Municipio:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="4">
                                  <FormGroup>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      id="municipio"
                                      onChange={(event) => {
                                        setComercio({
                                          ...comercio,
                                          idMunicipio: event.value,
                                        });
                                        //setNomMunicipio(event.label)
                                      }}
                                      options={municipios}
                                      placeholder="seleccione su municipio"
                                    />
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col sm="2" style={Spinenerstyle}>
                                  <RotateSpinner
                                    color={"#1261ff"}
                                    size={30}
                                    loading={true}
                                  />
                                </Col>
                              </>
                            )}

                            <>
                              <Label sm="1">
                                Colonia:<span className="text-danger">*</span>
                              </Label>
                              <Col sm="3">
                                <FormGroup>
                                  <Input
                                    name="Colonia"
                                    type="text"
                                    value={comercio.Colonia}
                                    onChange={(event) =>
                                      setComercio({
                                        ...comercio,
                                        Colonia: event.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="2">
                                <Button
                                  onClick={() => {
                                    setFlagEscogerColonia(true);
                                    console.log("ONCLIK");
                                  }}
                                >
                                  BUSCAR
                                </Button>
                                {flagEscogerColonia ? mini_table : <></>}
                              </Col>
                            </>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Codigo Postal:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  name="cp"
                                  type="text"
                                  pattern="[0-9]*"
                                  maxLength={5}
                                  value={comercio.CP}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      CP: event.target.value.replace(/\D/, ""),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">
                              Calle:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  id="calle"
                                  type="text"
                                  value={comercio.Calle}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Calle: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Número Exterior:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="numExt"
                                  type="text"
                                  value={comercio.NumExt}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      NumExt: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Número Interior</Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="numInt"
                                  type="text"
                                  value={comercio.NumInt}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      NumInt: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Latitud:</Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="calle"
                                  type="text"
                                  value={comercio.Latitude}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Latitude: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Longitud:</Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="calle"
                                  type="text"
                                  value={comercio.Longitude}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Longitude: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <CardHeader>
                            <CardTitle tag="h5">Datos del Contacto</CardTitle>
                          </CardHeader>
                          <Row>
                            <Label sm="2">
                              Nombre:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  name="nombre"
                                  type="text"
                                  value={comercio.Nombre}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Nombre: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Apellido Paterno:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  name="paterno"
                                  type="text"
                                  value={comercio.Paterno}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Paterno: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Apellido Materno:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  name="materno"
                                  type="text"
                                  value={comercio.Materno}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Materno: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Correo Electronico:</Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  name="correo"
                                  type="email"
                                  value={comercio.Correo}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Correo: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Telefono Celular
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="2">
                              <Label for="celular"></Label>
                              <FormGroup>
                                <Input
                                  id="celular"
                                  type="text"
                                  pattern="[0-9]*"
                                  maxLength={10}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      Celular: event.target.value.replace(
                                        /\D/,
                                        ""
                                      ),
                                    })
                                  }
                                  value={comercio.Celular}
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Telefono del negocio</Label>
                            <Col sm="2">
                              <Label for="telNegocio"></Label>
                              <FormGroup>
                                <Input
                                  id="TelNegocio"
                                  type="text"
                                  pattern="[0-9]*"
                                  maxLength={10}
                                  value={comercio.TelNegocio}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      TelNegocio: event.target.value.replace(
                                        /\D/,
                                        ""
                                      ),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Telefono de casa</Label>
                            <Col sm="2">
                              <Label for="telCasa"></Label>
                              <FormGroup>
                                <Input
                                  id="TelCasa"
                                  type="text"
                                  pattern="[0-9]*"
                                  maxLength={10}
                                  onChange={(event) =>
                                    setComercio({
                                      ...comercio,
                                      TelCasa: event.target.value.replace(
                                        /\D/,
                                        ""
                                      ),
                                    })
                                  }
                                  value={comercio.TelCasa}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <CardHeader>
                            <CardTitle tag="h5">Cobrador Acreditado</CardTitle>
                          </CardHeader>
                          <Row>
                            <Label sm="2">
                              CURP:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="8">
                              <FormGroup>
                                <Input
                                  name="CURP"
                                  type="text"
                                  value={comercio.Cobrador.CURP}
                                  onChange={(event) => {
                                    setComercio({
                                      ...comercio,
                                      Cobrador: {
                                        ...comercio.Cobrador,
                                        CURP: event.target.value.toUpperCase(),
                                      },
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <FormGroup>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    getCURP(comercio.Cobrador.CURP)
                                  }
                                >
                                  {loadingCURP ? (
                                    <ImpulseSpinner
                                      color={"#1261ff"}
                                      size={30}
                                      loading={loadingCURP}
                                    />
                                  ) : (
                                    ""
                                  )}

                                  {loadingCURP ? (
                                    <span>Validando</span>
                                  ) : (
                                    <span>Validar</span>
                                  )}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Nombre:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  name="nombre"
                                  type="text"
                                  value={comercio.Cobrador.Nombre}
                                  //onChange={event => setComercio({ ...comercio, Cobrador: { ...comercio.Cobrador, Nombre: event.target.value.toUpperCase() } })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Apellido Paterno:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  name="paterno"
                                  type="text"
                                  value={comercio.Cobrador.Paterno}
                                  //onChange={event => setComercio({ ...comercio, Cobrador: { ...comercio.Cobrador, Paterno: event.target.value.toUpperCase() } })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Apellido Materno:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="10">
                              <FormGroup>
                                <Input
                                  name="materno"
                                  type="text"
                                  value={comercio.Cobrador.Materno}
                                  //onChange={event => setComercio({ ...comercio, Cobrador: { ...comercio.Cobrador, Materno: event.target.value.toUpperCase() } })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter className="text-center">
                          {!flagbtnGuardar ? (
                            <Button color="primary" onClick={setNegocio}>
                              {
                                <ImpulseSpinner
                                  color={"#1261ff"}
                                  size={30}
                                  loading={flagSet}
                                />
                              }

                              {flagSet ? (
                                <span>Registrando..</span>
                              ) : (
                                <span>Registrar</span>
                              )}
                            </Button>
                          ) : (
                            <></>
                          )}
                        </CardFooter>
                      </Card>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default ComerciosAdd;

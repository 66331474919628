import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import {
  FormatedSolicitudForm,
  SolicitudForm,
} from "../data/interfaces/Cedula";
import { getVars } from "../GlobalStorage";
import {
  isEmptyOrInvalidString,
  isEmptyOrNullObject,
  isNullOrUndefined,
} from "../utils/emptyValidations";
import { SolicitudValidatorAnt } from "../data/validators/Cedula";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Notifications from "react-notify-toast";
import DateTimeComponent from "../components/DateTimeComponent";
import swal from "sweetalert";
import ListadoArchivos from "../components/Archivos/ListadoArchivos";

const SolicitudCedulaAddAnterior = (props) => {
  const { data, back } = props;
  const history = useHistory();
  const Datos = data ? data : null;
  const [userData, setUserData] = useState();
  const token = getVars("Token").token;
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [form, setForm] = useState(SolicitudForm);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [loadingForm, setIsLoadingForm] = useState(true);
  const [isForEdit, setIsForEdit] = useState(false);
  const [catalogs, setCatalogs] = useState({
    entidades: [],
    archivos_clasificacion: [],
    cat_parentesco_jefe_hogar: [],
    cat_parentesco_tutor: [],
    cat_situacion_actual: [],
    cat_estado_civil: [],
    municipios: [],
    localidades: [],
    articuladores: [],
  });
  const [errors, setErrors] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [errorsIne, setErrorsIne] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [datosTarjeta, setDatostarjeta] = useState({});
  const [loadingFolio, setLoadingFolio] = useState(false);
  const [loadingCURP, setLoadingCURP] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [MunLoaded, setMunLoaded] = useState(false);
  const [LocLoaded, setLocLoaded] = useState(false);
  const [enviada, setEnviada] = useState(0);
  const [comunidad, setComunidad] = useState(0);
  const [lengua, setLengua] = useState(0);

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 13);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
  }, [userData]);

  useEffect(() => {
    EjecutarApi({}, "getCatalogsCedula", token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          cat.localidades = [];
          setCatalogs(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
    setMunLoaded(true);
    setLocLoaded(true);
  }, [token]);

  useEffect(() => {
    setIsLoadingForm(true);
    let data = { ...form };
    data.Files = [];
    data.ArchivosClasificacion = [];
    if (Datos !== null) {
      EjecutarApi({}, `cedula/getArchivosByIdV/${Datos.id}`, token, "GET")
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results && res.success) {
            setIsForEdit(true);
            setEnviada(Datos.idEstatus);
            setComunidad(
              Datos.ComunidadIndigena && Datos.ComunidadIndigena.length !== 0
                ? 1
                : 0
            );
            setLengua(Datos.Dialecto && Datos.Dialecto.length !== 0 ? 1 : 0);
            data.Files = res.data.Archivos;
            data.ArchivosClasificacion = res.data.ArchivosClasificacion;
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
            back();
          }
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
          back();
        });
      data = FormatedSolicitudForm(Datos);
      setIsLoadingForm(false);
    }
    setTimeout(() => {
      setForm(data);
      setIsLoadingForm(false);
      setFormLoaded(true);
    }, 4000);
  }, [Datos]);

  useEffect(() => {
    if (form.idEntidadVive === 12) {
      let formCatalogs = {};
      if (catalogs.municipios.length > 0) {
        const MunicipioVive = catalogs.municipios.find(
          (x) => x.label === form.MunicipioVive
        );
        if (!isNullOrUndefined(MunicipioVive)) {
          formCatalogs.MunicipioVive = MunicipioVive.value;
        }
      }
      if (catalogs.localidades.length > 0) {
        const LocalidadVive = catalogs.localidades.find(
          (x) => x.label === form.LocalidadVive
        );
        if (!isNullOrUndefined(LocalidadVive)) {
          formCatalogs.LocalidadVive = LocalidadVive.value;
        }
      }
      setForm({
        ...form,
        ...formCatalogs,
      });
    }
  }, [
    catalogs.municipios,
    catalogs.localidades,
    loadingForm,
    formLoaded,
    Datos,
  ]);

  const getLocalities = (municipio) => {
    EjecutarApi(
      {},
      `cedula/getLocalidadesByMunicipio/${municipio}`,
      token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatalogs({
            ...catalogs,
            localidades: res.data,
          });
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const getAsentamiento = (localidad) => {
    EjecutarApi({}, `cedula/getTipoAsentamiento/${localidad}`, token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setForm({
            ...form,
            TipoAsentamiento: res.data,
          });
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const validateData = async (type, value) => {
    var urlencoded = new URLSearchParams();
    let folio = type === "Folio" ? value : "";
    let curp = type === "CURP" ? value.toUpperCase() : "";
    urlencoded.append("token", "dfee39bd-acd2-4c95-514d-74384d530496");
    urlencoded.append("Folio", folio);
    urlencoded.append("CURP", curp);

    var requestInfo = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: urlencoded,
      redirect: "follow",
    };
    setLoadingCURP(true);
    setLoadingFolio(true);

    fetch(
      "https://peb.guanajuato.gob.mx/Tarjeta/api/APIBusquedaDatos/SETBusquedaCLUB",
      requestInfo
    )
      .then((response) => {
        return response.json();
      })
      .then((dataRS) => {
        if (dataRS.result == 0) {
          if (type === "CURP") {
            validateCurp(value);
          } else {
            setLoadingCURP(false);
            swal({
              title: "¡Atención!",
              text: "No se encuentra la persona con los datos proporcionados",
              icon: "warning",
            });
          }
          setLoadingFolio(false);
        } else {
          setLoadingFolio(false);
          setLoadingCURP(false);
          setDatostarjeta(dataRS.data);
        }
      })
      .catch((error) => {
        setLoadingFolio(false);
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  const validateCurp = (CURP) => {
    if (CURP.length < 18) {
      return;
    }
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/A94A3BAC-C920-4290-82FD-C1F2A4044DBB`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        if (dataRS.Mensaje === "OK") {
          const response = dataRS.Resultado;
          const fechaNacimiento = moment(response.fechNac, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          let data = {
            Nombre: response.nombres,
            Paterno: response.apellido1,
            Materno: response.apellido2,
            Sexo: response.sexo,
            FechaNacimiento: fechaNacimiento,
            Edad: getAgeByDate(fechaNacimiento),
            idEntidadNacimiento: getEstadoNacimiento(response.cveEntidadNac),
          };
          setForm({
            ...form,
            ...data,
          });
          setDatostarjeta({});
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: dataRS.Mensaje,
            icon: "warning",
          });
        }
        setLoadingCURP(false);
      })
      .catch((error) => {
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  function verifyCURP(CURP) {
    return new Promise((resolve) => {
      let flag;
      if (CURP.length < 18) {
        resolve(false);
      }
      fetch(
        `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/A94A3BAC-C920-4290-82FD-C1F2A4044DBB`
      )
        .then((res) => res.json())
        .then((dataRS) => {
          if (dataRS.Mensaje != "OK") {
            resolve(false);
          } else resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  useEffect(() => {
    if (!isEmptyOrNullObject(datosTarjeta)) {
      let data = {
        CURP: datosTarjeta[0].CURP,
        Sexo: datosTarjeta[0].Sexo,
        Edad: getAgeByDate(datosTarjeta[0].FechaNacimiento),
        FechaNacimiento: moment(
          datosTarjeta[0].FechaNacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        FolioTarjetaImpulso: datosTarjeta[0].Folio ? datosTarjeta[0].Folio : "",
        Nombre: datosTarjeta[0].Nombres,
        Paterno: datosTarjeta[0].PrimerApellido,
        Materno: datosTarjeta[0].SegundoApellido,
      };
      if (
        !isEmptyOrInvalidString(datosTarjeta[0].Folio) ||
        !isEmptyOrInvalidString(datosTarjeta[0].CURP)
      ) {
        data.TarjetaImpulso = 3;
        data.ContactoTarjetaImpulso = 0;
      }
      data.idEntidadNacimiento = getEstadoNacimiento(
        datosTarjeta[0].EntidadNacimiento
      );
      setForm({
        ...form,
        ...data,
      });
    }
  }, [datosTarjeta]);

  useEffect(() => {
    if (!isNaN(parseInt(form.MunicipioVive))) {
      getLocalities(form.MunicipioVive);
    }
  }, [form.MunicipioVive]);

  useEffect(() => {
    if (!isNaN(parseInt(form.LocalidadVive))) {
      getAsentamiento(form.LocalidadVive);
    }
  }, [form.LocalidadVive]);

  const getAgeByDate = (fechaNacimiento) => {
    if (fechaNacimiento) {
      let anio = fechaNacimiento.split("/");
      let posicion = 2;
      if (anio.length < 2) {
        anio = fechaNacimiento.split("-");
        posicion = 0;
      }
      return moment().diff(anio[posicion], "years");
    } else return 0;
  };

  const getEstadoNacimiento = (estadoCurp) => {
    const found = catalogs.entidades.find(
      (element) => element.Clave_CURP === estadoCurp
    );
    if (!isNullOrUndefined(found)) {
      return found.value;
    }
    return "";
  };

  const handleChangeArchivos = (files) => {
    setForm({
      ...form,
      Files: files,
    });
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setForm({
      ...form,
      ArchivosClasificacion: arrayClasificacion,
    });
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setForm({
      ...form,
      Files: files,
      ArchivosClasificacion: arrayClasificacion,
    });
  };

  const handleChangeEstadoVive = (value) => {
    setForm({
      ...form,
      idEntidadVive: value,
      LocalidadVive: "",
      MunicipioVive: "",
    });
  };

  const saveAlert = (status) => {
    let text = "";
    let valor = 0;
    switch (status) {
      case 1:
        text =
          "¿Está seguro de poner la solicitud como 'Lista para revisión'?, no podrá ser editada después";
        valor = 1;
        break;
      case 2:
        text =
          "¿Está seguro de poner la solicitud como 'Lista para enviar'?, no podrá ser editada después";
        valor = 2;
        break;
      case 3:
        text = "¿Está seguro de habiliar la solicitud ?";
        valor = 3;
        break;
      case 4:
        text =
          "¿Está seguro de guardar los cambios? (La solicitud ya ha sido enviada a ventanilla)";
        valor = 4;
        break;
    }
    swal({
      title: "¡Atención!",
      text: text,
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        saveSolicitud(valor);
      }
    });
  };

  const saveSolicitud = async (redirect = 0) => {
    const hasErrors = SolicitudValidatorAnt(form);
    setErrors(hasErrors);
    if (!hasErrors.error) {
      setIsLoadingForm(true);
      let url;
      if (isForEdit) {
        url = "updateSolicitudCedula";
      } else {
        url = "createSolicitudCedula";
        setForm({
          ...form,
          Formato: 1,
        });
      }

      let formData = { ...form };
      let oldFiles = [];
      let newFiles = [];
      let oldClasification = [];
      let newClasification = [];
      let flag = false;

      formData.Files.forEach((file, index) => {
        if (isNullOrUndefined(file.id)) {
          newFiles.push(file);
          if (isNullOrUndefined(formData.ArchivosClasificacion[index])) {
            flag = true;
            return;
          }
          newClasification.push(formData.ArchivosClasificacion[index]);
        } else {
          oldFiles.push(file);
          if (isNullOrUndefined(formData.ArchivosClasificacion[index])) {
            flag = true;
            return;
          }
          oldClasification.push(formData.ArchivosClasificacion[index]);
        }
      });

      if (flag) {
        swal({
          title: "Alerta",
          text: "Debe seleccionar una clasificación para cada archivo",
          icon: "warning",
        });
        return;
      }
      if (formData.CURP) {
        const valida = await verifyCURP(formData.CURP);
        if (!valida) {
          swal({
            title: "Alerta",
            text: "El CURP ingresado es incorrecto",
            icon: "warning",
          });
          setIsLoadingForm(false);
          return;
        }
      }
      formData.OldFiles = oldFiles;
      formData.OldClasificacion = oldClasification;
      formData.NewFiles = newFiles;
      formData.NewClasificacion = newClasification;
      formData.Files = [];
      formData.ArchivosClasificacion = [];
      if (formData.idEntidadVive !== "" && formData.idEntidadVive === 12) {
        formData.MunicipioVive =
          formData.MunicipioVive !== "" && formData.MunicipioVive != null
            ? catalogs.municipios.find(
                (x) => x.value === parseInt(formData.MunicipioVive)
              ).label
            : "";
        formData.LocalidadVive =
          formData.LocalidadVive !== "" && formData.LocalidadVive != null
            ? catalogs.localidades.find(
                (x) => x.value === parseInt(formData.LocalidadVive)
              ).label
            : "";
      }

      formData.NoIntVive = form.NoIntVive === "" ? "S/N" : form.NoIntVive;

      switch (redirect) {
        case 1:
          formData.idEstatus = 10;
          break;
        case 2:
          formData.ListaParaEnviar = 1;
          formData.idEstatus = 9;
          break;
        case 3:
          formData.ListaParaEnviar = 0;
          formData.idEstatus = 1;
          break;
        case 4:
          formData.Actualizada = 1;
          break;
        default:
          formData.ListaParaEnviar = 0;
          formData.idEstatus = 1;
          break;
      }

      formData.programa = "VALE GRANDEZA";
      uploadFiles({ data: formData, api: url, token })
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            swal({
              title: "Guardado con éxito",
              text: "",
              icon: "success",
            });
            setIsLoadingForm(false);
            back();
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
          }
          setIsLoadingForm(false);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
        });
    } else {
      form.ListaParaEnviar = 0;
    }
  };

  const sendIGTO = (id) => {
    setIsLoadingForm(true);
    const token = getVars("Token");
    const data = { id: id };
    EjecutarApi(data, "cedula/enviarIGTO", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          swal({
            title: "Solicitud enviada con éxito",
            text: `Folio PVG - ${res.message}`,
            icon: "success",
          });
          back();
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setIsLoadingForm(false);
      })
      .catch((e) => {
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
        setIsLoadingForm(false);
      });
  };

  const deleteAlert = () => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro de eliminar la solicitud?",
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        deleteSolicitud();
      }
    });
  };

  const deleteSolicitud = () => {
    setIsLoadingForm(true);
    EjecutarApi({ id: form.id }, "deleteSolicitudCedula", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Eliminada con éxito!",
            icon: "success",
          });
          back();
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setIsLoadingForm(false);
      })
      .catch((error) => {
        setIsLoadingForm(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const handleChangeMunicipio = (municipio) => {
    if (municipio !== null && municipio !== "") {
      getLocalities(municipio);
      setForm({
        ...form,
        MunicipioVive: municipio,
      });
    } else {
      setForm({
        ...form,
        MunicipioVive: "",
        LocalidadVive: "",
      });
    }
  };

  useEffect(() => {
    if (!isNaN(parseInt(form.FechaINE))) {
      if (form.FechaINE.length === 4) {
        let fecha_actual = new Date();
        let aa_actual = fecha_actual.getFullYear();
        if (form.FechaINE !== null && form.FechaINE !== "") {
          //if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
          if (parseInt(form.FechaINE) < 2022) {
            const errors = {
              error: true,
              seccion: 1,
              message: "La vigencia de la Identificación Oficial no es válida",
            };
            setErrorsIne(errors);
          } else {
            const errors = { error: false, seccion: -1, message: "" };
            setErrorsIne(errors);
          }
        }
      } else {
        if (form.FechaINE.length > 0) {
          const errors = {
            error: true,
            seccion: 1,
            message: "La vigencia de la Identificación Oficial no es válida",
          };
          setErrorsIne(errors);
        }
      }
    }
  }, [form.FechaINE]);

  return (
    <>
      <Notifications />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Button color="success" onClick={back}>
                  Regresar
                </Button>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5" style={{ textAlign: "center" }}>
                  {isForEdit
                    ? enviada == 8
                      ? "Solicitud Enviada"
                      : "Editar solicitud"
                    : "Nueva Solicitud"}
                  {loadingForm && <Spinner />}
                </CardTitle>
                {formLoaded && MunLoaded && LocLoaded && (
                  <Form>
                    <Col sm="12" style={{ textAlign: "center", color: "red" }}>
                      {form.idEstatus === 9 ? (
                        <CardText>
                          La solicitud fue marcada como lista para enviar, NO se
                          puede editar
                        </CardText>
                      ) : form.idEstatus === 10 ? (
                        <CardText>
                          La solicitud esta en revisión, NO se puede editar
                        </CardText>
                      ) : form.idEstatus === 8 ? (
                        <CardText>
                          La solicitud fue enviada a Ventanilla, NO puede
                          editarse
                        </CardText>
                      ) : (
                        <CardText></CardText>
                      )}
                    </Col>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 0
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <Row>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Fecha solicitud</Label>
                          <span className="text-danger">*</span>
                          <DateTimeComponent
                            id="FechaSolicitud"
                            name="FechaSolicitud"
                            dateFormat="YYYY-MM-DD"
                            maxDate={moment()
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            minDate={new Date("2020-01-01")}
                            timeFormat={false}
                            closeOnSelect={true}
                            value={form.FechaSolicitud}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                FechaSolicitud: moment(e._d).format(
                                  "YYYY-MM-DD"
                                ),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Folio tarjeta impulso</Label>
                          <Input
                            id="folio"
                            type="text"
                            value={form.FolioTarjetaImpulso}
                            maxLength={10}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                FolioTarjetaImpulso: event.target.value,
                              })
                            }
                          />
                          {form.ListaParaEnviar == 0 && (
                            <Button
                              size="sm"
                              color="primary"
                              disabled={form.FolioTarjetaImpulso.length === 0}
                              onClick={() =>
                                validateData("Folio", form.FolioTarjetaImpulso)
                              }
                            >
                              Validar {loadingFolio && <Spinner size="sm" />}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            CURP
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CURP"
                            type="text"
                            value={form.CURP}
                            maxLength={18}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CURP: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          {form.ListaParaEnviar == 0 && (
                            <Button
                              size="sm"
                              color="primary"
                              disabled={form.CURP.length != 18}
                              onClick={() => validateData("CURP", form.CURP)}
                            >
                              Validar {loadingCURP && <Spinner size="sm" />}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            Folio de solicitud
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Folio"
                            type="text"
                            value={form.Folio}
                            maxLength={18}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Folio: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 0 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          (errors.error && errors.seccion === 1) ||
                          (errorsIne.error && errorsIne.seccion === 1)
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      I. DATOS DEL SOLICITANTE
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Nombre(s) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Nombre}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Nombre: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Primer apellido
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="paterno"
                            type="text"
                            value={form.Paterno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Paterno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Segundo apellido
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Materno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Materno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Fecha de nacimiento
                            <span className="text-danger">*</span>
                          </Label>
                          <DateTimeComponent
                            id="FechaNacimiento"
                            name="FechaNacimiento"
                            dateFormat="YYYY-MM-DD"
                            maxDate={moment()
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            minDate={new Date("1920-01-01")}
                            value={form.FechaNacimiento}
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                FechaNacimiento: moment(e._d).format(
                                  "YYYY-MM-DD"
                                ),
                              })
                            }
                            inputProps={{ readOnly: true }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Edad
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Edad}
                            maxLength={3}
                            pattern="[0-9]*"
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Edad: event.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label check>
                            Sexo
                            <span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="Hombre"
                                  type="radio"
                                  checked={
                                    form.Sexo === "H" || form.Sexo === ""
                                  }
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Sexo: "H",
                                    })
                                  }
                                />{" "}
                                <Label check>Hombre</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="Mujer"
                                  type="radio"
                                  checked={form.Sexo === "M"}
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Sexo: "M",
                                    })
                                  }
                                />{" "}
                                <Label check>Mujer</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Entidad de nacimiento
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="EntidadNacimiento"
                            type="select"
                            value={form.idEntidadNacimiento}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idEntidadNacimiento: e.target.value,
                              })
                            }
                          >
                            <option key={"default"} value={""}></option>
                            {catalogs.entidades.map((entidad) => (
                              <option key={entidad.value} value={entidad.value}>
                                {entidad.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>RFC</Label>
                          <Input
                            name="CURP"
                            type="text"
                            maxLength={13}
                            value={form.RFC}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                RFC: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Estado civil
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="EstadoCivil"
                            type="select"
                            value={form.idEstadoCivil}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idEstadoCivil: e.target.value,
                              })
                            }
                          >
                            <option key={"default"} value={""}></option>

                            {catalogs.cat_estado_civil.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Parentesco con Jefa(e) del hogar:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="JefeHogar"
                            type="select"
                            value={form.idParentescoJefeHogar}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idParentescoJefeHogar: e.target.value,
                              })
                            }
                          >
                            <option key={"default"} value={""}></option>

                            {catalogs.cat_parentesco_jefe_hogar.map(
                              (element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              )
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="6">
                        <FormGroup>
                          <Label>
                            Numero de hijos:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="NumHijos"
                            type="text"
                            value={form.NumHijos}
                            maxLength={2}
                            pattern="[0-9]*"
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NumHijos: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="6">
                        <FormGroup>
                          <Label>
                            Numero de hijas:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="NumHijas"
                            type="text"
                            maxLength={2}
                            pattern="[0-9]*"
                            value={form.NumHijas}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NumHijas: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Pueblo o comunidad indígena:</Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="NoComunidad"
                                  type="radio"
                                  checked={comunidad === 0}
                                  onChange={() => {
                                    setComunidad(0);
                                    setForm({
                                      ...form,
                                      ComunidadIndigena: "",
                                    });
                                  }}
                                />{" "}
                                <Label check>No</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="SiComunidad"
                                  type="radio"
                                  checked={
                                    comunidad === 1 ||
                                    form.ComunidadIndigena.length !== 0
                                  }
                                  onChange={() => setComunidad(1)}
                                />{" "}
                                <Label check>Sí</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Indique el Pueblo o comunidad indígena :
                          </Label>
                          <Input
                            name="ComunidadIndigena"
                            type="text"
                            disabled={
                              comunidad === 0 &&
                              form.ComunidadIndigena.length === 0
                            }
                            value={form.ComunidadIndigena}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                ComunidadIndigena: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Lengua indígena o dialecto:</Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="NoDialecto"
                                  type="radio"
                                  checked={lengua === 0}
                                  onChange={() => {
                                    setLengua(0);
                                    setForm({
                                      ...form,
                                      Dialecto: "",
                                    });
                                  }}
                                />{" "}
                                <Label check>No</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="SiDialecto"
                                  type="radio"
                                  checked={
                                    lengua === 1 || form.Dialecto.length !== 0
                                  }
                                  onChange={() => setLengua(1)}
                                />{" "}
                                <Label check>Sí</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Indique la Lengua indígena o dialecto:</Label>
                          <Input
                            name="Dialecto"
                            type="text"
                            value={form.Dialecto}
                            disabled={
                              lengua === 0 && form.Dialecto.length === 0
                            }
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Dialecto: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            ¿Se considera afro mexicano(a) o afrodescendiente? :
                            <span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="noAfro"
                                  type="radio"
                                  checked={
                                    form.Afromexicano === 0 ||
                                    form.Afromexicano === ""
                                  }
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Afromexicano: 0,
                                    })
                                  }
                                />{" "}
                                <Label check>No</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="siAfro"
                                  type="radio"
                                  checked={form.Afromexicano === 1}
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Afromexicano: 1,
                                    })
                                  }
                                />{" "}
                                <Label check>Sí</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Por su situación actual, usted se considera:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="SituacionActual"
                            type="select"
                            value={form.idSituacionActual}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idSituacionActual: e.target.value,
                              })
                            }
                          >
                            <option key={"default"} value={""}></option>

                            {catalogs.cat_situacion_actual.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            ¿Usted está interesada(o) en obtener la Tarjeta
                            Impulso?
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="TarjetaImpulso"
                            type="select"
                            value={form.TarjetaImpulso}
                            disabled={
                              datosTarjeta.Persona_Folio &&
                              datosTarjeta.Persona_Folio !== ""
                            }
                            onChange={(e) =>
                              setForm({
                                ...form,
                                TarjetaImpulso: e.target.value,
                              })
                            }
                          >
                            {[
                              { value: "", label: "" },
                              { value: 2, label: "No" },
                              { value: 1, label: "Si" },
                              { value: 3, label: "Ya cuenta con tarjeta" },
                            ].map((element) => (
                              <option key={element.label} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            ¿Autoriza que un servidor(a) publico(a) lo contacte
                            para tramitar la Tarjeta Impulso? :
                            <span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="TarjetaNo"
                                  type="radio"
                                  checked={form.ContactoTarjetaImpulso === 0}
                                  disabled={
                                    datosTarjeta.Persona_Folio &&
                                    datosTarjeta.Persona_Folio !== ""
                                  }
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      ContactoTarjetaImpulso: 0,
                                    })
                                  }
                                />{" "}
                                <Label check>No</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="TarjetaSi"
                                  type="radio"
                                  checked={
                                    datosTarjeta.Persona_Folio &&
                                    form.ContactoTarjetaImpulso === 1
                                  }
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      ContactoTarjetaImpulso: 1,
                                    })
                                  }
                                />{" "}
                                <Label check>Sí</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Vigencia INE:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="FechaINE"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={4}
                            value={form.FechaINE}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                FechaINE: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      {errors.error && errors.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      ) : errorsIne.error && errorsIne.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errorsIne.message}
                          </CardText>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>

                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 2
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      III. DATOS DE CONTACTO
                    </CardSubtitle>
                    <Row>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            Teléfono celular:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="telefono"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Celular}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Celular: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Correo electrónico:</Label>
                          <Input
                            name="telefono"
                            type="text"
                            value={form.Correo}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Correo: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Teléfono fijo:</Label>
                          <Input
                            name="telefono"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Telefono}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Telefono: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Teléfono de recados:</Label>
                          <Input
                            name="telRecados"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.TelRecados}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                TelRecados: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 2 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 3
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    {/* <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      IV. DATOS DE PADRE, MADRE O TUTOR(A) (en caso de aplicar)
                    </CardSubtitle>
                    <Row>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Parentesco con la persona solicitante:</Label>
                          <Input
                            name="SituacionActual"
                            type="select"
                            value={form.idParentescoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idParentescoTutor: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catalogs.cat_parentesco_tutor.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Nombre(s):</Label>
                          <Input
                            name="NombreTutor"
                            type="text"
                            value={form.NombreTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NombreTutor: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Primer apellido:</Label>
                          <Input
                            name="PaternoTutor"
                            type="text"
                            value={form.PaternoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                PaternoTutor: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Segundo apellido:</Label>
                          <Input
                            name="MaternoTutor"
                            type="text"
                            value={form.MaternoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                MaternoTutor: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Fecha de nacimiento</Label>
                          <DateTimeComponent
                            id="FechaNacimientoTutor"
                            name="FechaNacimientoTutor"
                            dateFormat="YYYY-MM-DD"
                            maxDate={moment()
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            minDate={new Date("1920-01-01")}
                            value={form.FechaNacimientoTutor}
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                FechaNacimientoTutor: moment(e._d).format(
                                  "YYYY-MM-DD"
                                ),
                              })
                            }
                            inputProps={{ readOnly: true }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Edad: </Label>
                          <Input
                            name="EdadTutor"
                            type="number"
                            value={form.EdadTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                EdadTutor: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label check>Sexo </Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="HombreTutor"
                                  type="radio"
                                  checked={form.SexoTutor === "H"}
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      SexoTutor: "H",
                                    })
                                  }
                                />{" "}
                                <Label check>Hombre</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="MujerTutor"
                                  type="radio"
                                  checked={form.SexoTutor === "M"}
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      SexoTutor: "M",
                                    })
                                  }
                                />{" "}
                                <Label check>Mujer</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Entidad de nacimiento: </Label>
                          <Input
                            name="EntidadNacimientoTutor"
                            type="select"
                            value={form.idEntidadNacimientoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idEntidadNacimientoTutor: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catalogs.entidades.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>CURP: </Label>
                          <Input
                            name="CURPTutor"
                            type="text"
                            value={form.CURPTutor}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CURPTutor: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Teléfono: </Label>
                          <Input
                            name="TelefonoTutor"
                            type="text"
                            value={form.TelefonoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                TelefonoTutor: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Correo:</Label>
                          <Input
                            name="CorreoTutor"
                            type="text"
                            value={form.CorreoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CorreoTutor: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 3 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 4
                            ? "red"
                            : "#E8E8E8",
                      }}
                    /> */}
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      V. DATOS DEL APOYO SOLICITADO
                    </CardSubtitle>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label>
                            Descripción de la necesidad del solicitante:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="NecesidadSolicitante"
                            type="textarea"
                            value={form.NecesidadSolicitante}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NecesidadSolicitante: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Costo estimado para atender la necesidad:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CostoNecesidad"
                            type="number"
                            value={form.CostoNecesidad}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CostoNecesidad: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 3 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 4
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      VI. DATOS DE DOMICILIO
                    </CardSubtitle>
                    <Row>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Estado: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="idEntidadVive"
                            type="select"
                            disabled={true}
                            value={form.idEntidadVive}
                            onChange={(e) =>
                              handleChangeEstadoVive(e.target.value)
                            }
                          >
                            <option value={""}></option>
                            {catalogs.entidades.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <>
                          {!loadingForm && (
                            <FormGroup>
                              <Label>Municipio:</Label>
                              <span className="text-danger">*</span>
                              {loadingCatalogs && <Spinner size="sm" />}
                              <Input
                                name="MunicipioVive"
                                type="select"
                                disabled={loadingCatalogs}
                                value={form.MunicipioVive}
                                onChange={(e) =>
                                  handleChangeMunicipio(e.target.value)
                                }
                              >
                                <option value={""}></option>
                                {catalogs.municipios.map((element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          )}
                        </>
                      </Col>
                      <Col sm="6" xs="6">
                        <>
                          <FormGroup>
                            <Label>Localidad:</Label>
                            <span className="text-danger">*</span>
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="MunicipioVive"
                              type="select"
                              disabled={
                                loadingCatalogs || form.MunicipioVive === ""
                              }
                              value={form.LocalidadVive}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  LocalidadVive: e.target.value,
                                  //TipoAsentamiento: e.target.Ambito,
                                });
                              }}
                            >
                              <option value={""}></option>
                              {catalogs.localidades.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Tipo de asentamiento:</Label>
                          <span className="text-danger">*</span>
                          <Input
                            name="TipoAsentamiento"
                            type="text"
                            value={form.TipoAsentamiento}
                            disabled="true"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Código Postal:</Label>
                          <span className="text-danger">*</span>
                          <Input
                            name="CPVive"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={5}
                            value={form.CPVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CPVive: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="6">
                        <FormGroup>
                          <Label>
                            Colonia: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="ColoniaVive"
                            type="text"
                            value={form.ColoniaVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                ColoniaVive: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            No. Exterior: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="NoExtVive"
                            type="text"
                            value={form.NoExtVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NoExtVive: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>No. Interior: </Label>
                          <Input
                            name="NoIntVive"
                            type="text"
                            value={form.NoIntVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NoIntVive: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      {/* <Col sm="3" xs="3">
                        <FormGroup>
                          <Label>
                            Latitud: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Latitud"
                            type="text"
                            value={form.Latitud}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Latitud: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="3">
                        <FormGroup>
                          <Label>
                            Longitud: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Longitud"
                            type="text"
                            value={form.Longitud}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Longitud: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col> */}

                      <Col sm="12" xs="12">
                        <FormGroup>
                          <Label>
                            Calle: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CalleVive"
                            type="text"
                            value={form.CalleVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CalleVive: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12" xs="12">
                        <FormGroup>
                          <Label>
                            Referencias: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Referencias"
                            type="textarea"
                            value={form.Referencias}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Referencias: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 4 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 5
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      II. DATOS DE INGRESOS
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Ingreso mensual percibido:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="IngresoMensual"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={6}
                            value={form.IngresoMensual}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                IngresoMensual: e.target.value.replace(
                                  /\D/,
                                  ""
                                ),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Otros ingresos mensuales:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="OtrosIngresos"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={6}
                            value={form.OtrosIngresos}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                OtrosIngresos: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Total ingreso mensual percibido:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="TotalIngreso"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={8}
                            value={form.TotalIngreso}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                TotalIngreso: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Ocupación de la persona que aporta los ingresos al
                            hogar :<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="OcupacionJefeHogar"
                            type="text"
                            value={form.OcupacionJefeHogar}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                OcupacionJefeHogar: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Numero de personas dependientes del ingreso mensual
                            total:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="PersonasDependientes"
                            type="text"
                            maxLength={2}
                            pattern="[0-9]*"
                            value={form.PersonasDependientes}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                PersonasDependientes: e.target.value.replace(
                                  /\D/,
                                  ""
                                ),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Cálculo de ingreso mensual per cápita de
                            dependientes:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="IngresoPercapita"
                            type="text"
                            maxLength={6}
                            pattern="[0-9]*"
                            value={form.IngresoPercapita}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                IngresoPercapita: e.target.value.replace(
                                  /\D/,
                                  ""
                                ),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      {form.TipoAsentamiento === null ||
                      form.TipoAsentamiento === "" ? (
                        <></>
                      ) : form.TipoAsentamiento === "RURAL" ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "black", textAlign: "center" }}
                          >
                            El ingreso mensual pércapita máximo para el
                            asentamiento de tipo {form.TipoAsentamiento} es de
                            <span className="text-danger"> * $2,870.00 * </span>
                          </CardText>
                        </Col>
                      ) : (
                        <Col xs="12">
                          <CardText
                            style={{ color: "black", textAlign: "center" }}
                          >
                            El ingreso mensual pércapita máximo para el
                            asentamiento de tipo {form.TipoAsentamiento} es de
                            <span className="text-danger"> * $4,042.00 * </span>
                          </CardText>
                        </Col>
                      )}

                      {errors.error && errors.seccion === 5 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 6
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      VII. DATOS DE ENLACE
                    </CardSubtitle>
                    <Row>
                      <Col sm="12" xs="12">
                        <FormGroup>
                          <Label>Persona que levantó solicitud en campo:</Label>
                          <span className="text-danger">*</span>
                          {loadingCatalogs && <Spinner size="sm" />}
                          <Input
                            name="Enlace"
                            type="select"
                            disabled={loadingCatalogs}
                            value={form.idEnlace}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idEnlace: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catalogs.articuladores.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 6 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 7
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      VIII. ARCHIVOS DE EVIDENCIA (CARPETA DIGITAL)
                    </CardSubtitle>
                    <Row>
                      <ListadoArchivos
                        files={form.Files}
                        archivosClasificacion={form.ArchivosClasificacion}
                        catalogClasificacion={catalogs.archivos_clasificacion}
                        handleChangeArchivos={handleChangeArchivos}
                        handleChangeClasificacion={handleChangeClasificacion}
                        handleDeleteFile={handleDeleteFile}
                        loadingCatalogs={loadingCatalogs}
                        onlyForView={form.ListaParaEnviar > 0}
                      />
                      {errors.error && errors.seccion === 7 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm="12" style={{ textAlign: "center" }}>
                        <Button
                          color="danger"
                          disabled={loadingForm}
                          onClick={back}
                        >
                          Cancelar
                        </Button>

                        {form.ListaParaEnviar === 0 ? (
                          <>
                            {isForEdit &&
                              menu.Eliminar === 1 &&
                              form.idEstatus === 1 &&
                              !loadingForm && (
                                <Button
                                  color="danger"
                                  disabled={loadingForm}
                                  onClick={() => deleteAlert()}
                                >
                                  Eliminar
                                </Button>
                              )}

                            {menu.Agregar === 1 &&
                              menu.Editar === 1 &&
                              form.idEstatus === 1 &&
                              !loadingForm && (
                                <Button onClick={() => saveSolicitud()}>
                                  Guardar
                                </Button>
                              )}

                            {menu.Agregar === 1 &&
                              menu.Editar === 1 &&
                              menu.Seguimiento === 1 &&
                              form.idEstatus === 1 &&
                              menu.ViewAll === 0 &&
                              !loadingForm && (
                                <Button
                                  color="primary"
                                  disabled={loadingForm}
                                  onClick={() => saveAlert(1)}
                                >
                                  Guardar y lista para revisión
                                </Button>
                              )}

                            {menu.Agregar === 1 &&
                              menu.Editar === 1 &&
                              menu.Seguimiento === 1 &&
                              menu.Imprimir === 0 &&
                              menu.ViewAll === 1 &&
                              form.idEstatus === 10 &&
                              !loadingForm && (
                                <Button
                                  color="warning"
                                  disabled={loadingForm}
                                  onClick={() => saveAlert(3)}
                                >
                                  HABILITAR SOLICITUD
                                </Button>
                              )}

                            {menu.Agregar === 1 &&
                              menu.Editar === 1 &&
                              menu.Seguimiento === 1 &&
                              menu.Imprimir === 0 &&
                              menu.ViewAll === 1 &&
                              !loadingForm && (
                                <Button
                                  color="primary"
                                  disabled={loadingForm}
                                  onClick={() => saveAlert(2)}
                                >
                                  Guardar y lista para enviar
                                </Button>
                              )}
                          </>
                        ) : (
                          <>
                            {/* {menu.Imprimir === 1 && enviada != 8 && (
                          <Button
                            color="success"
                            onClick={() => sendIGTO(form.id)}
                          >
                            Enviar a ventanilla
                          </Button>
                        )} */}

                            {userData.user.id === 1279 && !loadingForm && (
                              <Button
                                color="primary"
                                disabled={loadingForm}
                                onClick={() => saveAlert(4)}
                              >
                                Guardar actualización
                              </Button>
                            )}
                          </>
                        )}
                        {loadingForm && <Spinner />}
                      </Col>
                      <Col
                        sm="12"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        {errors.error && (
                          <CardText>
                            Existen errores, por favor verifique el formulario
                          </CardText>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SolicitudCedulaAddAnterior;
